<template>
  <v-card>
    <v-card-title
      class="primary--text pb-10">
      注册账号
    </v-card-title>
    <v-card-text
      class="pt-0">
      <validation-observer
        v-slot="{ handleSubmit }"
        ref="form">
        <v-form
          @submit.prevent="handleSubmit(submit)">
          <validation-provider
            name="用户名"
            vid="username"
            rules="required"
            v-slot="{ errors }">
            <v-text-field
              label="用户名"
              v-model="username"
              :error-messages="errors"
              outlined>
            </v-text-field>
          </validation-provider>
          <validation-provider
            name="邮箱"
            vid="email"
            rules="required|email"
            v-slot="{ errors }">
            <v-text-field
              label="邮箱"
              v-model="email"
              type="email"
              :error-messages="errors"
              outlined>
            </v-text-field>
          </validation-provider>
          <validation-provider
            name="密码"
            rules="required|min:8|max:100"
            v-slot="{ errors }"
            vid="password">
            <v-text-field
              label="密码"
              v-model="password"
              type="password"
              :error-messages="errors"
              outlined>
            </v-text-field>
          </validation-provider>
          <!-- :rules="`confirmed:password|${password ? 'emptyConfirm' : ''}`" -->
          <validation-provider
            name="确认密码"
            :rules="`confirmed:password|emptyConfirm`"
            v-slot="{ errors }"
            :mode="confirmPassword">
            <v-text-field
              label="确认密码"
              v-model="repeatPassword"
              type="password" 
              :error-messages="errors"
              outlined>
            </v-text-field>
          </validation-provider>
          <v-list-item
            class="px-0">
            <v-btn
              width="100%"
              color="primary"
              type="submit">
              注册
            </v-btn>
          </v-list-item>
        </v-form>
      </validation-observer>
      <v-list-item
        class="px-0">
        <router-link to="/login">«&nbsp;返回登录</router-link>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      repeatPassword: ''
    }
  },
  methods: {
    submit() {
      this.$store.commit('snackbar/HIDE');
      this.$store.dispatch('account/register', {
        username: this.username,
        email: this.email,
        passwd: this.password
      }).then(res => {
        // "{"type":"data","_data":{"uid":23,"token":"/EpqpxWFZQ/zg3dgaH6GecPWySP5Jtgm+uZL3UC9Zqa1XmjQdoeuzrjfrOkdHcKC","user":{"id":23,"username":"ashasdh","nickname":"ashasdh","email":"","passwd":"","settings":"{}"}}}"
        // "{"type":"error","_data":"invalid_username"}"
        // "{"type":"error","_data":"create_user_failed"}"
        if (res.type === 'error') {
          const errMsg = {
            'invalid_username': { username: ['无效的用户名'] },
            'invalid_email': { email: ['无效的邮箱'] },
            'email_already_exists': { email: ['邮箱已被使用'] },
            'send_email_failed': { email: ['发送验证邮件失败，请检查邮箱。'] },
          };
          this.$refs.form.setErrors(errMsg[res.error]);
        } else if (res.type === 'data') {        
          this.$emit('register-success');
        }
      }).catch(err => {
        this.$store.commit('snackbar/SHOW', {
          type: 'error',
          content: err
        });
      });
    },
    confirmPassword({ errors }) {
      if (errors.length) {
        return {
          on: ['input', 'change']
        };
      }

      return {
        on: ['blur', 'submit']
      };
    }
  }
}
</script>