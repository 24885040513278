import { objectPick } from "./util";

export class DataModel {
    toJson(arg?: any) {
        return JSON.stringify(this.toObj(arg));
    }
    toObj(arg?: any): any {
        const ctor = Object.getPrototypeOf(this).constructor as typeof DataModel;
        return ctor.toObj(this, arg);
    }
    static toObj<T extends typeof DataModel>(this: T, instance: InstanceType<T>, arg?: any): any;
    static toObj<T extends typeof DataModel>(this: T, instance: null, arg?: any): null;
    static toObj<T extends typeof DataModel>(this: T, instance: InstanceType<T>, arg?: any): any {
        if (!instance) return null;
        const obj = {} as any;
        const keys = this.keys;
        for (const [k, t] of keys) {
            obj[k] = this._toObjRepalcer(k, (instance as any)[k], arg);
        }
        return obj;
    }

    protected static _toObjRepalcer(key: string, val: any, arg?: any) {
        return key.startsWith('_') ? undefined : val instanceof DataModel ? val.toObj() : val;
    }

    static fromJson<T extends typeof DataModel>(this: T, json: string): InstanceType<T> {
        var parsed = JSON.parse(json);
        return this.fromObj<T>(parsed)!;
    }

    protected static _keys: [string, string][] | null = null;
    protected static _onlyKeys: string[] | null = null;
    protected static get keys() {
        this.init();
        return this._keys!;
    }
    protected static init() {
        if (!Object.prototype.hasOwnProperty.call(this, "_keys") || !this._keys) {
            var model = new this();
            this._keys = Object.keys(model).map(x => [x, typeof (model as any)[x]]);
            this._onlyKeys = this._keys.map(x => x[0]);
        }
    }
    public static getKeys<T extends typeof DataModel>(this: T): (keyof InstanceType<T>)[] {
        this.init();
        return this._onlyKeys as any;
    }

    static fromObj<T extends typeof DataModel>(this: T, obj: Partial<InstanceType<T>>): InstanceType<T>;
    static fromObj<T extends typeof DataModel>(this: T, obj: null | undefined): null;
    static fromObj<T extends typeof DataModel>(this: T, obj: Partial<InstanceType<T>> | null | undefined): InstanceType<T> | null {
        if (!obj) return null;
        var result = new this() as any;
        this.init();
        for (const [k, t] of this._keys!) {
            if (typeof (obj as any)[k] === t)
                result[k] = this._fromObjReplacer(k, (obj as any)[k]);
            else if (typeof (obj as any)[k] == "number" && t == "boolean")
                result[k] = this._fromObjReplacer(k, !!((obj as any)[k]));
        }
        // console.info({ keys: this._keys, obj, result })
        return result as InstanceType<T>;
    }
    protected static _fromObjReplacer(key: string, val: any): any {
        return val;
    }
}

export class User extends DataModel {
    id = 0;
    username = "";
    nickname = "";
    email = "";
    passwd = "";
    settings = "";
    verified = false;

    toObj(mode: "all" | "public" | "private" = "public") {
        if (mode == 'all') return super.toObj();
        return objectPick(this, [
            "id", "username", "nickname",
            ...(mode == "private" ? ["email", "settings", "verified"] as const : [])
        ]);
    }
}

export class LoginRequest extends DataModel {
    username = "";
    email = "";
    passwd = "";
}

export class LoginResponse extends DataModel {
    token = "";
    user: User | null = null;

    static _toObjRepalcer(key: string, val: any) {
        if (key == "user") return User.toObj(val as User, "private");
        return val;
    }
    static _fromObjReplacer(key: string, val: any) {
        if (key == "user") return User.fromObj(val);
        return val;
    }
}

export class RegisterResponse extends DataModel {
    status: "need-verify" | "finished" = "" as any;

    /** Only if "finished" */
    token = "";
    /** Only if "finished" */
    user: User | null = null;

    static _toObjRepalcer(key: string, val: any) {
        if (key == "user") return User.toObj(val as User, "private");
        return super._toObjRepalcer(key, val);
    }
    static _fromObjReplacer(key: string, val: any) {
        if (key == "user") return User.fromObj(val);
        return val;
    }
}

export class VerificationRequest extends DataModel {
    info = "";
}

export class ResetPasswordRequest extends DataModel {
    email = "";
}

export class TaskItem extends DataModel {
    id = 0;
    uid = 0;
    text = "";
    tag = "";
    setting = "";
    done = false;
    estSec = 0;
    usedSec = 0;
    createdAt = 0;
    date = 0;
}

export class TimerItem extends DataModel {
    id = 0;
    uid = 0;
    task: TaskItem | null = null;
    start = 0;
    duration = -1;
    stop = 0;

    static _fromObjReplacer(key: string, val: any) {
        if (key == "task") return TaskItem.fromObj(val);
        return val;
    }
}


export class TaskList extends DataModel {
    tasks: TaskItem[] = [];

    static _toObjRepalcer(key: string, val: any) {
        if (key == "tasks") return val && (val as any[]).map(x => TaskItem.toObj(x));
        return super._toObjRepalcer(key, val);
    }
    static _fromObjReplacer(key: string, val: any) {
        if (key == "tasks") return val && (val as any[]).map(x => TaskItem.fromObj(x));
        return val;
    }
}

export class TimerList extends DataModel {
    timers: TimerItem[] = [];

    static _toObjRepalcer(key: string, val: any) {
        if (key == "timers") return val && (val as any[]).map(x => TimerItem.toObj(x));
        return super._toObjRepalcer(key, val);
    }
    static _fromObjReplacer(key: string, val: any) {
        if (key == "timers") return val && (val as any[]).map(x => TimerItem.fromObj(x));
        return val;
    }
}