<template>
  <v-list
    flat>
    <v-list-group
      :ripple="false">
      <template
        v-slot:activator>
        <v-list-item-title>
          Finished tasks
        </v-list-item-title>
      </template>
      <v-list>
        <task-item
          v-for="(task, index) in finishedTasks"
          :key="`finished${task.id}`"
          :task="task"
          :index="index">
        </task-item>
      </v-list>
    </v-list-group>
  </v-list>
</template>

<script>
import TaskItem from './TaskItem.vue';
import { tasksMixin } from '@/utils/mixin';

export default {
  props: ['range'],
  components: { TaskItem },
  mixins: [tasksMixin],
}
</script>

<style lang="sass">
  .v-list-item__icon
    margin-right: 8px !important
</style>