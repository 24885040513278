<template>
  <div 
    class="task-input px-4 pb-1"
    ref="taskInput">
    <validation-provider
      name="inputText"
      ref="inputText"
      rules="required">
      <v-text-field
        placeholder="添加一个任务 [Enter]"
        hide-details
        @keyup.enter="addTask"
        v-model="inputText">
        <task-item-tag
          class="mr-2"
          slot="append"
          v-for="tag in filtedTags"
          :key="tag.text"
          :color="tag.color"
          :showClose="true"
          @clickOnItemTagClose="removeTag(tag)">
          {{ tag.text }}
        </task-item-tag>
      </v-text-field>
    </validation-provider>
  </div>
</template>

<script>
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition';
import { ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import Bus from '@/utils/bus.js';
import TaskItemTag from './TaskItemTag.vue';

const myExpandTransition = createSimpleTransition('expand-transition');

Vue.component('expand-transition', myExpandTransition)


export default {
  props: ['tab'],
  components: { TaskItemTag, ValidationProvider },
  data() {
    return {
      inputText: '',
      search: null,
      now: this.dayjs().unix(),
    }
  },
  methods: {
async addTask() {
  this.$refs.inputText.validate().then(async ({ valid }) => {
    if (valid) {
      await this.$store.dispatch('tasks/addTask', {
        text: this.inputText,
        tag: this.filtedTags,
        date: [this.now, this.dayjs.unix(this.now).add(1, 'day').unix(), 0][this.tab],
        createdAt: this.now
      })
      this.inputText = '';
      Bus.$emit('addTaskDone');
      if (this.$store.state.account.user.settings.clearTagAfterAdd) this.inputTaskTags = [];
    }
  })
},
    removeTag(tag) {
      this.$store.commit('tasks/REMOVE_FILTER_TAG', {
        tag: tag
      });
      this.$forceUpdate();
      Bus.$emit('removeFiltedTag');
    }
  },
  computed: {
    filtedTags() {
      return this.$store.state.tasks.filtedTags;
    }
  }
}
</script>

<style lang="sass">
  .task-input
    .v-text-field__slot
      margin-left: 8px !important

    .v-input__append-inner
      order: -1 !important
      padding-left: 0 !important     

      .custom-chip:first-child
        margin-left: 0px !important
</style>