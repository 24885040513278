<template>
  <div class="task-date-picker-wrap">
    <v-menu
      :close-on-content-click="false"
      :offset-x="$vuetify.breakpoint.height / 2 <= 430"
      offset-y
      nudge-bottom="5"
      nudge-right="1"
      :value="datePickerShow">
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          small
          v-bind="attrs"
          v-on="on"
          @click.prevent="datePickerShow = true">
          <v-icon
            size="16"
            left
            class="mr-1">
            mdi-calendar
          </v-icon>
          {{   date >= 0 && 
              (dayjs(inputDate).isSame(dayjs(), 'day') && '今天' || 
               dayjs(inputDate).isSame(dayjs().add(1, 'day'), 'day') && '明天' || inputDate) || 'Date' }}
        </v-chip>
      </template>
      <v-card>
        <v-date-picker
          no-title
          v-model="inputDate"
          :allowed-dates="allowedDates">
        </v-date-picker>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click.prevent="datePickerShow = false">
            关闭
          </v-btn>
          <v-btn
            text
            @click="inputDate = dayjs().add(1, 'day').format('YYYY-MM-DD')">
            明天
          </v-btn>
          <v-btn
            text
            @click="inputDate = dayjs().format('YYYY-MM-DD')">
            今天
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'date',
    event: 'changed'
  },
  props: {
    date: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      datePickerShow: false,
      inputDate: ''
    }
  },
  methods: {
    allowedDates(date) {
      return this.dayjs(date).isSameOrAfter(this.dayjs().format('YYYY-MM-DD'));
    },
  },
  watch: {
    inputDate() {
      this.$emit('changed', this.dayjs(this.inputDate).unix());
    },
    date() {
      this.inputDate = this.dayjs.unix(this.$props.date).format('YYYY-MM-DD');   
    }
  },
  mounted() {
   this.inputDate = this.dayjs.unix(this.$props.date).format('YYYY-MM-DD');
  }
}
</script>

<style lang="sass">
  // .task-date-picker-wrap
  //   .v-menu__content
  //     max-width: initial !important
</style>