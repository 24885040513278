<template>
  <v-app>
    <snackbar></snackbar>
    <v-main
      style="background-color: #eef0f2;">
        <sidebar
          v-if="$store.state.account.token">
        </sidebar>
        
        <keep-alive>
              <main-progress></main-progress>
              <transition name="slide-fade"
                mode="out-in">
                <router-view v-if="!isLoadding"></router-view>
              </transition>
        </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import Sidebar from '@/views/Sidebar.vue';
import Snackbar from '@/components/Snackbar.vue';
// import MainProgress from './components/MainProgress.vue';

export default Vue.extend({  
  components: { Sidebar, Snackbar },
  computed: {
    isLoadding() {
      return this.$store.state.loadding;
    }
  }
})
</script>

<style lang="sass" scoped>
  .slide-fade-enter-active, .slide-fade-leave-active 
    transition: all .1s ease

  .slide-fade-enter, .slide-fade-leave-to 
    transform: translateY(20px)
    opacity: 0
</style>