<template>
  <task-time-input
    :title="title"
    :timeArr="tomatoTimeArr"
    :key="'tomato'"
    v-model="time">
  </task-time-input>
</template>

<script>
import TaskTimeInput from './TaskTimeInput.vue';

export default {
  components: { TaskTimeInput },
  model: {
    prop: 'tomatoTime',
    event: 'changed'
  },
  props: ['tomatoTime'],
  data() {
    return {
      title: '单个番茄时间',
      time: 0
    }
  },
  computed: {
    tomatoTimeArr() {
      return this.$store.state.account.user.settings.tomatoTime.filter(v => v);
    }
  },
  beforeMount() {
    this.time = this.$store.state.account.user.settings.lastUsedTomatoTime;
  },
  watch: {
    time() {
      this.$emit('changed', this.time);
    }
  }
}
</script>