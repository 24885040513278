<template>
  <task-time-input
    :title="title"
    :timeArr="breakingTimeArr"
    :key="'breaking'"
    v-model="time">
  </task-time-input>
</template>

<script>
import TaskTimeInput from './TaskTimeInput.vue';

export default {
  components: { TaskTimeInput },
  model: {
    prop: 'breakingTime',
    event: 'changed'
  },
  props: ['breakingTime'],
  data() {
    return {
      title: '休息时间',
      time: 0
    }
  },
  computed: {
    breakingTimeArr() {
      return this.$store.state.account.user.settings.breakingTime.filter(v => v);
    }
  },
  beforeMount() {
    this.time = this.$store.state.account.user.settings.lastUsedBreakingTime;
  },
  watch: {
    time() {
      this.$emit('changed', this.time);
    }
  }
}
</script>