import { render, staticRenderFns } from "./TaskTagPicker.vue?vue&type=template&id=3f95b0b2&"
import script from "./TaskTagPicker.vue?vue&type=script&lang=js&"
export * from "./TaskTagPicker.vue?vue&type=script&lang=js&"
import style0 from "./TaskTagPicker.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../cache/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../cache/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VCheckbox,VChip,VDivider,VHover,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemIcon,VMenu,VTextField})


/* vuetify-loader */
import installDirectives from "!../../../../../cache/node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
