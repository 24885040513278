<template>
  <v-list
    class="text-body-2 py-0" 
    elevation="1">
    <div 
      class="timer-daily-header d-flex justify-space-between align-center font-weight-medium py-4 px-4"
      style="minHeight: 32px">
      <span>{{ formatDate }}</span>
      <span>{{ dayjs.duration(totalDuratioin, 'seconds').format('mm:ss') }}</span>
    </div>
    <div
      ref="timerItemWrapper">
      <div
        v-for="(timerList, key) in sortedTimerList"
        :key="key">
        <timer-list-item
          v-if="timerList.length > 1"
          :timer="timerHeaderList[key]"
          :isHeader="true"
          :paddingLeft="false"
          :active="activeQuery[key]"
          @clickOnHeader="toggleVisibility(key)">
        </timer-list-item>
        <div
          v-if="activeQuery[key] || timerList.length <= 1">
          <timer-list-item
            v-for="(timer) in timerList"
            :key="timer.id"
            :timer="timer"
            :paddingLeft="timerList.length > 1"
            :active="activeQuery[key]">
          </timer-list-item>
        </div>
      </div>
    </div>
  </v-list>
</template>

<script>
import TimerListItem from './TimerListItem.vue';

export default {
  props: ['timers', 'date'],
  components: { TimerListItem },
  data() {
    return {
      showNotHeader: false,
      activeQuery: null
    }
  },
  computed: {
    formatDate() {
      const diff = this.dayjs().diff(this.date, 'day');
      if (diff < 2) return ['今日', '昨日'][diff];
      return this.date;
    },

    sortedTimerList() {
      return this.timers?.reduce((acc, cur) => {
        if (cur.duration === -1) return acc;
        const curLabel = cur.task === null ? null : cur.task.text;
        if (curLabel in acc)
          acc[curLabel].push(cur);
        else
          acc[curLabel] = [cur];
        return acc;
      }, {});
    },

    timerHeaderList() {
      return this._.zipObject(this._.keys(this.sortedTimerList), this._.values(this.sortedTimerList).map(arr => {
        return arr.reduce((acc, cur) => {
          const curStop = cur.start + cur.duration * 1000;
          acc.start = cur.start < acc.start ? cur.start : acc.start;
          acc.stop = curStop > acc.stop ? curStop : acc.stop;
          acc.duration += cur.duration;
          return acc;
        }, {
          timerNumber: arr.length,
          start: Date.now(),
          stop: 0,
          duration: 0,
          task: arr[0].task,
        })
      }));
    },

    totalDuratioin() {
      return this._.values(this.timerHeaderList).reduce((acc, cur) => {
        acc += cur.duration;
        return acc;
      }, 0)
    }
  },
  watch: {
    timerHeaderList() {
      this.activeQuery = this._.mergeWith(this.activeQuery, this.geneactiveQuery(), (old, src) => {
        return this._.isUndefined(old) ? src : old;
      }); 
    },
  },
  methods: {
    geneactiveQuery() {
      return this._.mapValues(this.timerHeaderList, () => false);
    },
    toggleVisibility(key) {
      this.activeQuery[key] = !this.activeQuery[key];
      this.$forceUpdate();
    },
    toggleDivider() {
      const timerItem = this.$refs.timerItemWrapper.querySelectorAll('.timer-item');
      const lastTimerItem = timerItem[timerItem.length - 1];
      lastTimerItem.classList.add('hide-divider');
    }
  },
  beforeMount() {
    this.activeQuery = this.geneactiveQuery();
  },
  updated() {
    this.toggleDivider();
  }
}
</script>