<template>
    <div
      class="d-flex flex-column align-center">
      <!-- <timer-select-task></timer-select-task> -->
      <v-btn
        elevation="2"
        rounded
        x-large
        :color="types[type].color"
        @click="types[type].func">
        <v-icon color="white">{{ types[type].icon }}</v-icon>
        <span 
          class="ml-2 white--text">
          {{ types[type].label }}
        </span>
      </v-btn>
    </div>
</template>

<script>
import Vue from 'vue';
import Bus from '@/utils/bus';

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'start'
    }
  },
  data() {
    return {
      types: {
        start: {
          label: '开始',
          icon: 'mdi-play',
          color: 'secondary',
          func: () => this.startTimer()
        }, 
        stop: {
          label: '停止',
          icon: 'mdi-stop',
          color: 'red',
          func: () => this.stopTimer()
        }, 
        edit: {
          label: 'SAVE',
          icon: 'mdi-check',
          color: 'success',
          func: () => this.stopTimer()
        }, 
      }
    }
  },
  methods: {
    startTimer() {
      this.$emit('clickOnStartTimer');
      Bus.$emit('clickOnStartTimer');
    },
    stopTimer() {
      this.$emit('clickOnStopTimer');
      Bus.$emit('clickOnStopTimer');
    }
  },
  computed: {
    hasOngoingTimer() {
      return !this._.isNull(this.$store.state.timer.ongoingTimer);
    }
  }
})
</script>