import _ from 'lodash';

const SAVE_TOKEN = (state, { token }) => {
  // "{"type":"data","_data":{"uid":18,"token":"ABVhR7DAgWZ0rBFB8YIG+Gyq6zoPZvkrfEoxStd7AIsQNsxIrpYhl8PeKX83hvqW","user":{"id":18,"username":"fronz","nickname":"fronz","email":"","passwd":"","settings":"{}"}}}"
  // user: {
  //   username: 'fronz',
  //   nickname: '',
  //   uid: '233',
  //   settings: {
  //     tomatoTime: [1500, 2100, 0],
  //     clearTagAfterAdd: true
  //   },
  //   token: '',
  // },
  // _.assign(state.user, data);
  state.token = token;
  window.localStorage.setItem('token', token);
};

const SAVE_USER = (state, { data }) => {
  _.assign(state.user, data);
  state.auth = true;
};

const CLEAR_TOKEN = (state) => {
  state.auth = false;
  state.token = '';
  window.localStorage.setItem('token', '');
};

const ADD_TAG = (state, { newTag }) => {
  state.user.settings.tag.push(newTag);
};

const DELETE_TAG = (state, { targetTag }) => {
  state.user.settings.tag = state.user.settings.tag.filter(tag => tag != targetTag);
};

const UPDATE_TAG = (state, { editedTag }) => {
  _.merge(state.user.settings.tag, editedTag);
};

const UPDATE_SETTING = (state, { target, newValue }) => {
  state.user.settings[target] = newValue;
};

const UPDATE_USER_INFO = (state, { userInfo }) => {
  _.assign(state.user, { ...userInfo });
};

export default {
  SAVE_TOKEN,
  SAVE_USER,
  CLEAR_TOKEN,
  ADD_TAG,
  DELETE_TAG,
  UPDATE_TAG,
  UPDATE_SETTING,
  UPDATE_USER_INFO
}