<template>
  <div>
    <register-card
      @register-success="registerSuccess = true"
      v-show="!registerSuccess">
    </register-card>
    <v-scroll-x-reverse-transition>
      <register-success
        v-show="registerSuccess">
      </register-success>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import RegisterCard from '@/components/Account/RegisterCard.vue';
import RegisterSuccess from '@/components/Account/RegisterSuccess.vue';


export default {
  components: { RegisterCard, RegisterSuccess },
  data() {
    return {
      registerSuccess: false
    }
  }
}
</script>