<template>
  <v-card
    class="login-card">
    <v-card-title
      class="primary--text pb-10">
      RESET PASSWORD
    </v-card-title>
      <v-card-text
        class="pt-0">
        <validation-observer
          v-slot="{ handleSubmit }">
          <v-form
            @submit.prevent="handleSubmit">
            <validation-provider
              name="Email"
              rules="required|email"
              v-slot="{ errors }">
              <v-text-field
                label="Your Email"
                type="email"
                v-model="email"
                outlined
                :error-messages="errors">
              </v-text-field>
            </validation-provider>
            <v-list-item
              class="px-0">
              <v-btn
                width="100%"
                color="primary"
                type="submit">
                Send reset link
              </v-btn>
            </v-list-item>
          </v-form>
        </validation-observer>
        <v-list-item
          class="px-0">
          <a 
            href="" 
            @click.prevent="$emit('clickOnToLogin')">
            «&nbsp;Back to login
          </a>
        </v-list-item>
      </v-card-text>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      email: ''
    }
  }
}
</script>

<style lang="sass" scoped>
  .v-card__subtitle, .v-card__text, .v-card__title
    padding: 60px 
</style>