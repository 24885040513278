export const tasksMixin = {
  computed: {
    tagedTasks() {
      return this.$store.getters['tasks/filterByTag'];
    },
    rangeTasks() {
      if (!this.range) return this.tagedTasks;
      return this.tagedTasks.filter(task => this.range.isSame(this.dayjs.unix(task.date), 'day'));
    },
    finishedTasks() {
      return this.rangeTasks.filter(task => task.done);
    },
    finishedTasksLength() {
      return this.finishedTasks.length;
    },
    unfinishedTasks() {
      return this.rangeTasks.filter(task => !task.done);
    },
    unfinishedTasksLength() {
      return this.unfinishedTasks.length;
    }
  },
};

export const tagMixin = {
  methods: {
    pushFiltedTags(tag) {
      if (this.hasEqualTag(tag)) return;
      this.$store.commit('tasks/ADD_FILTER_TAG', {
        filtedTag: tag
      });
    },
    hasEqualTag(tag) {
      if (this._.isEmpty(this.filtedTags)) return false;
      const filtedTagsText = this.filtedTags.map(tag => tag.text);
      return filtedTagsText.includes(tag.text );
    },
  }
}