import { DataModel, LoginRequest, LoginResponse, RegisterResponse, TaskItem, TaskList, TimerItem, TimerList, User, VerificationRequest } from "./models";

export class ApiResult<T> {
    readonly type: "error" | "data" | "raw";
    private readonly _data: any;
    get error() { return this.type == "error" ? this._data : null; }
    get data(): T { return this.type == "data" ? this._data : null; }
    get raw(): Response { return this.type == "raw" ? this._data : null; }
    constructor(type: ApiResult<T>['type'], data: any) {
        this.type = type;
        this._data = data;
    }
}

export class Client {
    apiBase: string;
    token: string | null = null;
    user: User | null = null;

    constructor(apiBase: string) {
        this.apiBase = apiBase;
    }

    async _fetch<T>(
        method: "GET" | "POST" | "PUT" | "DELETE",
        path: string,
        opt: { body?: any, type?: string, modelCtor?: typeof DataModel, token?: string; } = {}
    ): Promise<ApiResult<T>> {
        let { body, type, token = this.token } = opt;
        if (body && typeof body == "object" && !type) type = "json";
        if (type == "json") {
            body = typeof body == "object" ? JSON.stringify(body) : body;
            type = "application/json";
        } else if (type == "text") {
            type = "text/plain";
        }
        let headers = {} as Record<string, string>;
        if (type) headers["Content-Type"] = type;
        if (token) headers["Authorization"] = "Bearer " + token;
        try {
            var r = await fetch(this.apiBase + path, { method, body, headers });
        } catch (error) {
            console.warn("Client fetch error");
            return new ApiResult("error", "network_error");
        }
        const respType = r.headers.get("Content-Type");
        if (respType && /^application\/json[;$]/.test(respType)) {
            var json = await r.json();
            if ("error" in json) return new ApiResult("error", json["error"]);
            if (opt.modelCtor) json = opt.modelCtor.fromObj(json);
            return new ApiResult("data", json);
        } else {
            return new ApiResult("raw", r);
        }
    }

    /**
     * Errors:
            "network_error"
            "email_already_exists"
            "invalid_username"
            "invalid_email"
            "invalid_passwd"
            "send_email_failed"
     */
    async register(user: User) {
        var r = await this._fetch<RegisterResponse>("POST", "/user", {
            body: user,
            modelCtor: RegisterResponse
        });
        if (r.data) this.handleLoginResult(r.data);
        return r;
    }

    /**
     * Errors:
            "invalid_parameters"
            "user_not_found"
            "outdated_link"
            "invalid_link"
            "update_user_failed"
            "already_verified"
     */
    async verifyEmail(veriRequest: VerificationRequest) {
        var r = await this._fetch<RegisterResponse>("POST", "/verify-email", {
            body: veriRequest,
            modelCtor: RegisterResponse
        });
        if (r.data) this.handleLoginResult(r.data);
        return r;
    }

    /**
     * Errors:
            "network_error"
            "either_username_or_email_is_required"
            "invalid_passwd"
            "user_not_found"
            "wrong_passwd"
            "email_not_verified"
     */
    async login(req: LoginRequest) {
        var r = await this._fetch<LoginResponse>("POST", "/login", {
            body: req,
            modelCtor: LoginResponse
        });
        if (r.data) this.handleLoginResult(r.data);
        return r;
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "user_not_found"
     */
    async restoreLogin(token: string) {
        this.token = token;
        var r = await this.getUserInfo();
        if (r.data) this.user = r.data;
        return r;
    }

    private handleLoginResult(r: LoginResponse) {
        this.token = r.token;
        this.user = r.user;
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "user_not_found"
     */
    async getUserInfo(user: string | number = "me") {
        return await this._fetch<User>("GET", "/user/" + user, {
            modelCtor: User
        });
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "forbidden"
            "email_not_verified"
            "cannot_change_id"
            "cannot_change_verified"
            "cannot_change_email"
            "update_user_failed"
     */
    async saveUserInfo(newPasswd?: string) {
        return await this._fetch("PUT", "/user/me", {
            body: { ...this.user!.toObj("private"), passwd: newPasswd }
        });
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "email_not_verified"
     */
    async getTasks() {
        return await this._fetch<TaskList>("GET", "/user/me/task", {
            modelCtor: TaskList
        });
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "email_not_verified"
     */
    async addTask(task: TaskItem) {
        return await this._fetch<TaskItem>("POST", "/user/me/task", {
            body: task.toObj(),
            modelCtor: TaskItem
        });
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "email_not_verified"
            "invalid_id"
            "item_not_found"
            "cannot_change_uid"
            "cannot_change_id"
            "update_failed"
     */
    async updateTask(task: TaskItem) {
        return await this._fetch("PUT", "/user/me/task/" + task.id, {
            body: task.toObj()
        });
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "email_not_verified"
            "item_not_found"
     */
    async deleteTask(id: number) {
        return await this._fetch("DELETE", "/user/me/task/" + id);
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "email_not_verified"
     */
    async getTimers() {
        return await this._fetch<TimerList>("GET", "/user/me/timer", {
            modelCtor: TimerList
        });
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "email_not_verified"
     */
    async addTimer(timer: TimerItem) {
        return await this._fetch<TimerItem>("POST", "/user/me/timer", {
            body: timer.toObj(),
            modelCtor: TimerItem
        });
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "email_not_verified"
            "invalid_id"
            "item_not_found"
            "task_not_found"
            "cannot_change_uid"
            "cannot_change_id"
            "update_failed"
     */
    async updateTimer(timer: TimerItem) {
        return await this._fetch("PUT", "/user/me/timer/" + timer.id, {
            body: timer.toObj()
        });
    }

    /**
     * Errors:
            "network_error"
            "no_login"
            "email_not_verified"
            "item_not_found"
     */
    async deleteTimer(id: number) {
        return await this._fetch("DELETE", "/user/me/timer/" + id);
    }
}
