<template>
  <div>
    <transition-group name="slide-x-transition"
      mode="in-out">
    <login-card
      v-show="!showReset"
      @clickOnToReset="showReset = true"
      :key="$shortid.generate()">
    </login-card>
    <reset-card
      v-show="showReset"
      @clickOnToLogin="showReset = false"
      :key="$shortid.generate()">
    </reset-card>
    </transition-group>
  </div>
</template>

<script>
import LoginCard from '@/components/Account/LoginCard.vue';
import ResetCard from '@/components/Account/ResetCard.vue';



export default {
  components: { LoginCard, ResetCard },
  data() {
    return {
      showReset: false
    }
  }
}
</script>