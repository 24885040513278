import * as api from '@/api/api';
import _ from 'lodash';

const defaultSettings = {
  lastUsedDefaultTomatoTime: 1500,
  tomatoTime: [1500, 2100],
  lastUsedTomatoTime: 0,
  breakingTime: [300, 600],
  lastUsedBreakingTime: 0,
  clearTagAfterAdd: true,
  tag: [],
  colors: ["red", "pink", "purple", "deepPurple", "indigo", "blue", "lightBlue", "cyan", "teal", "green", "lightGreen", "yellow", "amber", "orange", "deepOrange", "brown", "blueGrey", "grey", "shades"],
};

const login = async ({ commit }, { email, passwd }) => {
  return api.cli.login(api.LoginRequest.fromObj({
    email: email,
    passwd: passwd
  })).then(res => {
    if (res.type === 'data') {
      commit('SAVE_TOKEN', {
        token: res.data.token
      });
      commit('SAVE_USER', {
        data: res.data.user
      });
    }
    return res;
  });
};

const register = async ({ commit }, { username, email, passwd }) =>{
  return api.cli.register(api.User.fromObj({
    username: username,
    email: email,
    passwd: passwd
  })).then(res => {
    if (res.type === 'data') {
      commit('SAVE_TOKEN', {
        token: res.data.token
      });
    }
    return res;
  });
};

const verifyEmail = async ({ commit }, info) => {
  return api.cli.verifyEmail(api.VerificationRequest.fromObj({
    info: info
  })).then(res => {
    if (res.type === 'data') {
      commit('SAVE_USER', {
        data: res.data.user
      });
      commit('SAVE_TOKEN', {
        token: res.data.token
      });
    }
    return res;
  })
};

const getUserInfo = async ({ state, commit }) => {
  api.cli.token = state.token;
  api.cli.restoreLogin(state.token);
  return api.cli.getUserInfo().then(res => {
    if (res.type === 'data') {
      let userInfo = res.data;
      const userSettings = JSON.parse(userInfo.settings);

      if (!_.isEqual(Object.keys(userSettings), Object.keys(defaultSettings)))
        // set default settings
        _.assign(userInfo, { settings: defaultSettings });
      else
        _.assign(userInfo, { settings: userSettings });

      commit('SAVE_USER', {
        data: userInfo
      });
    }
    return res;
  }).catch(err => {
    commit('snackbar/SHOW', {
      type: 'error',
      content: err
    })
  });
};

const updateUserInfo = async ({ state, commit }, userInfo) => {
  _.assign(api.cli.user, { ...userInfo });
  commit('UPDATE_USER_INFO', { userInfo: api.cli.user });
  return await api.cli.saveUserInfo();
};

const addTag = ({ commit, dispatch }, newTag) => {
  commit('ADD_TAG', { newTag });
  dispatch('updateSettings');
};

const deleteTag = ({ commit, dispatch }, targetTag) => {
  commit('DELETE_TAG', { targetTag });
  dispatch('updateSettings');
};

const updateTag = ({ commit, dispatch }, editedTag) => {
  commit('UPDATE_TAG', { editedTag });
  dispatch('updateSettings');
};

const updateSettings = ({ state }) => {
  api.cli.token = state.token;

  api.cli.user = api.User.fromObj({
    ...state.user,
    settings: JSON.stringify(state.user.settings)
  });

  api.cli.saveUserInfo();
};

const getColor = ({ state }) => {
  const colors = state.user.settings.colors;
  const randomColor = colors[_.random(0, colors.length - 1)];
  return randomColor;

} 

export default {
  login,
  register,
  verifyEmail,
  getUserInfo,
  addTag,
  deleteTag,
  updateTag,
  updateSettings,
  getColor,
  updateUserInfo
}