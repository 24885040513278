import Vue from 'vue';
import VueRouter from 'vue-router';
import Timer from '@/pages/Timer.vue';
import Report from '@/pages/Report.vue';
import Tasks from '@/pages/Tasks.vue';
import Login from '@/pages/Login.vue';
import Register from '@/pages/Register.vue';
// import Verify from '@/pages/Verify.vue';
import Account from '@/views/Account.vue';
import store from '@/store';

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: Account,
  children: [{
    path: 'login',
    name: 'login',
    component: Login
  }, {
    path: 'register',
    name: 'register',
    component: Register
  }, {
    path: '',
    redirect: '/timer'
  }]
}, {
  path: '/verify',
  name: 'verify',
  component: Timer
}, {
  path: '/timer',
  name: 'timer',
  component: Timer,
  meta: { requiresAuth: true }
}, {
  path: '/tasks',
  name: 'tasks',
  component: Tasks,
  meta: { requiresAuth: true }
}, {
  path: '/report',
  name: 'report',
  component: Report,
  meta: { requiresAuth: true }
}, { 
  path: '*', 
  redirect: '/timer' 
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const infLogin = () => {
  store.commit('snackbar/SHOW', {
    type: 'error',
    content: '请先登录！'
  });
  store.commit('account/CLEAR_TOKEN');
};

const infHasLogin = () => {
  store.commit('snackbar/SHOW', {
    type: 'success',
    content: '已登录。'
  });
};

const validateToken = async () => {
  const res = await store.dispatch('account/getUserInfo', store.state.token);
  if (res.type === 'data') {
    return true;
  } else {
    return false;
  }
};

const verifyEmail = async (info) => {
  await store.dispatch('account/verifyEmail', info).then(res =>{
    if (res.type === 'error') {
      const errMsg = {
        'invalid_parameters': 'Invalid parameters.',
        'user_not_found"e_or_email_is_required': '.',
        'outdated_link': 'Outdated link.',
        'invalid_link"': 'Invalid link.',
        'update_user_failed': 'Update user failed.',
        'already_verified': 'Already verified.',
        'internal_error': 'Internal error.',
      };
      store.commit('snackbar/SHOW', {
        type: 'error',
        content: errMsg[res._data]
      });
    } else if (res.type === 'data') {
      store.commit('snackbar/SHOW', {
        type: 'success',
        content: 'Login successful!'
      });
      router.push('timer');
    }
  });
};

router.beforeEach(async (to, from, next) => {
  
  if (to.name === 'verify') {
    verifyEmail(to.query.info)
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.account.auth) {
      if (store.state.account.token) {
        try {
          if (validateToken()) {
            next();
          } else {
            infLogin();
            next({ name: 'login' });
          } 
        } catch (err) {
          infLogin();
          next({ name: 'login' });
        }
      } else {
        infLogin();
        next({ name: 'login' });
      }
    } else {
      next();
    }
  } else {
    if (store.state.account.token && validateToken()) {
      infHasLogin();
      router.push({ path: 'timer' })
        .catch(() => {});
    } else {
      next();
    }
  }
});

const getDate = (namespace, getters, dispatch) => {
  if (!store.getters[namespace + '/' + getters]) {
    store.commit('START_LOADDING');
    store.dispatch(namespace + '/' + dispatch).then(() => {
      setTimeout(() => {
        store.commit('END_LOADDING')
      }, 500);
    });
  }
}

router.afterEach((to) => {
  switch (to.name) {
    case 'timer':
      getDate('tasks', 'hasTasks', 'getTasks');
      getDate('timer', 'hasTimers', 'getTimers');
      break;
  
    case 'tasks':
      getDate('tasks', 'hasTasks', 'getTasks');
      break;

    default:
      store.commit('END_LOADDING')
      break;
  }
});

export default router;
