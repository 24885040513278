<template>
  <v-navigation-drawer
    permanent
    app>
    <v-row
      class="fill-height nowrap"
      no-gutters>
      <v-navigation-drawer
        class="track-navigation-drawer text-center px-2 py-3 primary"
        permanent
        mini-variant
        mini-variant-width="72">
        <div>
          <avatar></avatar>
          <divider></divider>
          <v-list-item 
            class="navigation-drawer-item"
            link
            :class="{ 'primary--light': key === curRoute }"
            v-for="(item, key) in items"
            :to="item.to" 
            :key="item.title">
            <v-list-item-action>
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div>
          <v-list-item>
            <!-- <v-list-item-action>
              <v-icon color="white">settings</v-icon>
            </v-list-item-action> -->
            <settings></settings>
          </v-list-item>
        </div>
      </v-navigation-drawer>
      <v-list
        class="grow pa-0">
        <h3 class="links-title px-4 primary--text">{{ items[curRoute].title }}</h3>
        <div class="tabs-container px-4">
          <v-tabs 
            vertical
            v-model="curTabIndex">
            <v-tab
              v-for="tab in items[curRoute].tabs"
              :key="tab.name">
              <v-icon>{{ tab.icon }}</v-icon>
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </div>
      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>

<script>

import Vue from 'vue';
import Avatar from '@/components/Sidebar/Avatar.vue';
import Divider from '@/components/Sidebar/Divider.vue';
import Settings from '@/components/Sidebar/SidebarSetting.vue'

export default Vue.extend({
  components: {
    Avatar,
    Divider,
    Settings
  },
  data() {
    return {
      items: {
        timer: {
          title: "Track",
          icon: "mdi-target",
          to: { name: 'timer' },
          tabs: [{
            name: '计时器',
            icon: 'mdi-timer'
          }]
        },
        tasks: {
          title: "Tasks",
          icon: "mdi-format-list-checkbox",
          to: { name: 'tasks' },
          tabs: [{
            name: '今日',
            icon: 'mdi-calendar-today'
          }, {
            name: '本周',
            icon: 'mdi-calendar-week',
          }, {
            name: '全部',
            icon: 'mdi-calendar-blank',
          }]
        }
      },
      tabs: [{
        name: 'Today',
        icon: 'mdi-calendar-today'
      }, {
        name: 'This Week',
        icon: 'mdi-calendar-week',
      }, {
        name: 'All',
        icon: 'mdi-calendar-blank',
      }],
      curTabIndex: 0
    }
  },
  computed: {
    curRoute() {
      return this.$route.name;
    }
  },
  watch: {
    curTabIndex() {
      this.$store.commit('UPDATE_TAB_INDEX', { newTabIndex: this.curTabIndex })
    }
  }
})
</script>

<style lang="sass">
  .track-navigation-drawer
    & > .v-navigation-drawer__content
      display: flex
      flex-direction: column
      justify-content: space-between

  .tabs-container
    .v-tab
      justify-content: start
      gap: 12px

  .navigation-drawer-item
    height: 56px
    border-radius: $border-radius-root
    margin-top: 8px

    &:hover
      background-color: var(--v-primary--light-base)

  .links-title
    line-height: 48px

  .link-item
    border-radius: $border-radius-root
  
    &:not(:first-child)
      margin-top: 8px

    &::before
      border-radius: $border-radius-root

    .v-list-item__action
      margin-right: 16px !important

    .v-list-item__title
      color: var(--v-primary--light-base)
</style>