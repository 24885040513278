import Vue from 'vue';
import Vuetify from 'vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);


export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#344955',
        'primary--light': '#5F7481',
        secondary: '#F9AA33',
        accent: '#8c9eff',
        error: '#b71c1c',
        grey: '#9E9E9E'
      },
    },
    options: {
      customProperties: true
    },
  },
  icons: {
    iconfont: 'md',
  },
});
