import _ from 'lodash';

const tasks = (state) => {
  if (_.isNull(state.tasks)) return [];
  return state.tasks;
};

const hasTasks = (state) => {
  return !_.isNull(state.tasks);
}

const filterByTag = (state, getters) => {
  if (_.isEmpty(state.filtedTags)) return getters.tasks;

  const filtedTagsText = state.filtedTags.map(tag => tag.text);
  
  return getters.tasks.filter(task => {
    const taskTagsText = task.tag.map(tag => tag.text);
    return _.isEmpty(_.difference(filtedTagsText, taskTagsText));
  });
}

export default {
  tasks,
  hasTasks,
  filterByTag
}