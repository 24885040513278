<template>
  <div class="task-item-controls">
    <span
      v-for="item in operations"
      :key="item.name">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span
            v-on="on">
            <v-list-item-icon
              class="mx-0">
                <v-icon
                  @click.prevent="item.func()"
                  size="18">
                  {{ item.icon }}
                </v-icon>
            </v-list-item-icon>
          </span>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
// import { apiConfig } from '../utils/api';

export default Vue.extend({
  props: ['tid', 'description'],
  data() {
    return {
      operations: [{
        name: '删除',
        icon: 'mdi-delete',
        func: (task) => this.deleteTask(task),
      }, {
        name: '编辑',
        icon: 'mdi-circle-edit-outline',
        func: () => this.clickOnEditTask()
      }, {
        name: '开始',
        icon: 'mdi-play-circle',
        func: () => this.startTimer()
      }]
    }
  },
  methods:  {
    startTimer() {
      this.$store.commit('timer/CREATE_TIMER');
    },
    clickOnEditTask() {
      this.$emit('clickOnEditTask');
    },
    async deleteTask() {
      await this.$store.dispatch('tasks/deleteTask', this.tid);
    }
  },
});
</script>

<style lang="sass" scoped>
  .v-list-item__icon
    margin: 12px 0

    .v-progress-circular
      position: relative
      top: -2px
</style>