var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{staticClass:"px-4 timer-item py-4",class:{ 'active': _vm.active },attrs:{"ripple":false}},[(_vm.isHeader)?_c('v-chip',{staticClass:"px-0 d-flex justify-center mr-2",staticStyle:{"width":"24px"},attrs:{"small":"","label":""},on:{"click":function($event){return _vm.$emit('clickOnHeader')}}},[_vm._v(" "+_vm._s(_vm.timer.timerNumber)+" ")]):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item-content',{staticClass:"timer-item-content pr-0 py-0 justify-space-between",class:_vm.paddingLeft ? 'pl-8' : ''},[_c('div',{staticClass:"d-flex align-center justify-space-between pr-6",staticStyle:{"flex-grow":"1 !important"}},[_c('span',{staticClass:"cursor-pointer",class:{ 'grey--text': !!!_vm.timer.task }},[_vm._v(_vm._s(!!_vm.timer.task ? _vm.timer.task.text : '无'))]),(!_vm.isHeader)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"cursor-pointer",style:({ 'visibility': hover && 'visible' || 'hidden' })},on),[_c('v-icon',[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-list',[_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(" 指定 ")])],1)]}}],null,true),model:{value:(_vm.showSelectTaskDialog),callback:function ($$v) {_vm.showSelectTaskDialog=$$v},expression:"showSelectTaskDialog"}},[_c('task-selector',{on:{"selectTask":_vm.selectTask}})],1),_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.deleteTimer(_vm.timer.id)}}},[_c('v-list-item-title',[_vm._v(" 删除 ")])],1)],1)],1):_vm._e()],1),_c('div',[_c('span',{staticClass:"mr-12"},[_vm._v(_vm._s(_vm.start + ' - ' + _vm.end))]),_c('span',[_vm._v(_vm._s(_vm.dayjs.duration(_vm.timer.duration, 'seconds').format('mm:ss')))])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }