import _ from 'lodash';
import dayjs from 'dayjs';

const formatMin = (min) => {
  const res = min ? (min < 10 ? `0${String(min)}` : String(min)) : '00';
  return res + ':';
}

const leftStr = ({ mode, total, left, ongoingTimer }) => {
  switch (mode) {
    case 0: {
      const min = parseInt(left.as('m'), 10);
      const sec = left.format('ss');
      return left ? formatMin(min) + sec : dayjs.duration(total, 's').format('mm:ss');
    }
  
    case 1: {
      if (_.isNull(ongoingTimer)) return '00:00';
      const format = (v) => v ? v < 10 ? `0${String(v)}` : String(v) : '00';

      const duration = ongoingTimer.duration;
      const min = parseInt(duration / 60, 10);
      const sec = duration - min * 60;

      return format(min) + ':' + format(sec); 
    }

    default:
      break;
  }
};

const leftSec = ({ left }) => {
  return left.as('seconds');
};

const hasTimers = ({ ongoingTimer, timers }) => {
  return !(_.isNull(ongoingTimer) && _.isNull(timers));
};

const hasOngoingTask = ({ ongoingTask }) => {
  return !_.isNull(ongoingTask);
}

export default {
  leftStr,
  leftSec,
  hasTimers,
  hasOngoingTask,
}