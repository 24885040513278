
export default {
  START_LOADDING(state) {
    state.loadding = true;
  },
  END_LOADDING(state) {
    state.loadding = false;
  },
  UPDATE_TAB_INDEX(state, { newTabIndex }) {
    state.tabIndex = newTabIndex;
  }
}