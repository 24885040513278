import * as api from '@/api/api';
import _ from 'lodash';

const parseTaskStr = (task) => {
  return _.mapValues(task, (v, k) => {
    switch (k) {
      case 'tag':
        return JSON.parse(v);

      case 'setting':
        return v.trim().length < 2 ? Object() : JSON.parse(v);
    
      default:
        return v;
    }
  });
}

const parseTasksStr = (tasks) => {
  return _.map(tasks, (task) => {
    const res = parseTaskStr(task);
    return res;
  });
};

const parseTaskArr = (task) => {
  const taskObj = JSON.parse(JSON.stringify(task));
  return _.mapValues(taskObj, (v, k) => {
    return k === 'tag' ? JSON.stringify(v) : k === 'setting' ? JSON.stringify(v) : v
  });
};

const addTask = async ({ commit, rootState }, task) => {
  api.cli.token = rootState.account.token;

  // set default settings
  task.setting = {
    timerMode: -1
  };

  const parsedTask = parseTaskArr(task);

  console.log(parsedTask);

  return api.cli.addTask(api.TaskItem.fromObj(parsedTask)).then(res => {
    const parsedArrTask = parseTaskStr(res.data);
    if (res.type === 'data') {
      commit('ADD_TASK', parsedArrTask);
    }
    return res;
  }).catch(err => {
    commit('snackbar/SHOW', {
      type: 'error',
      content: err
    })
  });
};

const getTasks = async ({ commit, rootState }) => {
  api.cli.token = rootState.account.token;
  return api.cli.getTasks().then(res => {
    if (res.type === 'data') {
      // parse tag string to array
      const tasks = parseTasksStr(res.data.tasks);
      commit('GET_TASKS', tasks);
      
    }
    return res;
  }).catch(err => {
    commit('snackbar/SHOW', {
      type: 'error',
      content: err
    })
  });
};

const updateTask = {
  root: true,
  handler: async ({ commit, rootState }, task) => {
    api.cli.token = rootState.account.token;
    const editedTask = parseTaskArr(task);
    return api.cli.updateTask(api.TaskItem.fromObj(editedTask)).then(res => {
      return res;
    }).catch(err => {
      commit('snackbar/SHOW', {
        type: 'error',
        content: err
      })
    });
  },
}

const deleteTask = async ({ state, rootState }, id) => {
  api.cli.token = rootState.account.token;
  state.tasks = state.tasks.filter(task => task.id != id);
  return api.cli.deleteTask(id);
};

export default {
  addTask,
  getTasks,
  updateTask,
  deleteTask
};