import { required, email, min, max, confirmed, integer } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';

setInteractionMode('custom', ({ errors, value }) => {
  if (errors.length) {
    return {
      on: ['input', 'change']
    };
  }

  if (value) {
    return {
      on: ['blur']
    };
  }

  return { on: ['submit'] };
})

extend('required', {
  ...required,
  message: '{_field_}不能为空',
});

extend('email', {
  ...email,
  message: '{_field_}无效',
});

extend('min', {
  ...min,
  message: '{_field_}长度必须大于 {length} 位',
});

extend('max', {
  ...max,
  message: '{_field_}长度必须小于 {length} 位',
});

extend('confirmed', {
  ...confirmed,
  message: `密码不一致`,
});

extend('emptyConfirm', {
  ...required,
  message: `请确认密码`
});

extend('integer', {
  ...integer
});



