import dayjs from 'dayjs';
import _ from 'lodash';

const CHANGE_MODE = (state, { mode }) => {
  state.mode = mode;
}

const CHANGE_TOTAL = (state, { newValue }) => {
  state.total = newValue;
  state.left = dayjs.duration(state.total, 's');
  if (!_.isNull(state.ongoingTask)) state.ongoingTask.setting.tomatoTime = newValue;
}

const SELECT_TASK = (state, { task }) => {
  state.ongoingTask = task;
  if (task.setting.timerMode === 0)
    state.total = task.setting.tomatoTime;
};

const GET_TIMERS = (state, { timers }) => {
  state.timers = timers;
};

const INIT_TIMER = (state) => {
  state.left = dayjs.duration(state.total, 's');
};

const RESUME_TIMER = (state, { resumedTimer, duration }) => {
  state.left = dayjs.duration(state.total - duration, 's');
  state.ongoingTimer.duration = duration;
  if (!_.isNull(resumedTimer.task)) {
    state.ongoingTask = resumedTimer.task;
    state.ongoingTask.usedSec = duration;
  }
    
};

const CREATE_TIMER = (state, { timer }) => {
  state.ongoingTimer = timer;
  // debugger
  if (state.mode === 1)
    state.ongoingTimer.duration = 0;
};

const DELETE_TIMER = (state, { id }) => {
  state.timers = state.timers.filter(timer => timer.id !== id);
};

const UPDATE_TIMERS = (state, { timer }) => {
  const timerIndex = state.timers.findIndex(t => t.id === timer.id);
  const newTimers = [...state.timers];
  newTimers[timerIndex] = timer;
  state.timers = newTimers;
};

const UPDATE_TIMER = (state, { timer }) => {
  state.ongoingTimer = _.assign(state.ongoingTimer, timer);
};

const INCREMENT_TIMER = (state) => {
  if (state.ongoingTask) state.ongoingTask.usedSec++;
  state.left = state.left.subtract(1, 's');
  state.ongoingTimer.duration++;
};

const STOP_TIMER = (state) => {
  state.left = dayjs.duration(0, 's');
  state.ongoingTimer.duration = state.ongoingTimer.duration === -1 ? 0 : state.ongoingTimer.duration;
  state.timers.unshift(state.ongoingTimer);  
  state.ongoingTimer = null;
  clearInterval(state.interval);
};

const SET_INTERVAL = (state, { interval }) => {
  state.interval = interval;
};

const SET_REACTIVE = (state, { target, source }) => {
  state[target] = { ...state[target], source };
  console.log(target, source);
}

export default {
  CHANGE_MODE,
  INIT_TIMER,
  SELECT_TASK,
  GET_TIMERS,
  RESUME_TIMER,
  CREATE_TIMER,
  DELETE_TIMER,
  UPDATE_TIMERS,
  UPDATE_TIMER,
  INCREMENT_TIMER,
  STOP_TIMER,
  SET_INTERVAL,
  CHANGE_TOTAL,
  SET_REACTIVE
}