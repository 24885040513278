<template>
  <v-card-text
    class="py-0">
    <span class="subheading">{{ title }}</span>
    <v-spacer></v-spacer>
    <div class="tomato-time-picker-wrap">
      <v-chip-group
        active-class="blue--text"
        v-model="inputTime">
        <v-chip
          v-for="(time, index) in timeArr"
          :key="time" 
          :value="timeArr[index]"
          @click="$emit('changed', time); customTimeShow = false; customTime = time / 60">
          {{ `${parseInt(time / 60)} 分钟` }}
        </v-chip>
        <v-chip
          :value="0"
          @click="customTimeShow = !customTimeShow">
          自定义
        </v-chip>   
      </v-chip-group>
      <v-slider
        class="align-items-center align-center included-custom-time"
        v-model="customTime"
        :max="60"
        step="5"
        hide-details
        v-show="JSON.parse(JSON.stringify(customTimeShow))">
        <template v-slot:append>
          <v-text-field
            v-model="customTime"
            class="mt-0 pt-0 px-0"
            style="width: 50px"
            hide-details
            dense
            flat
            type="number"
            suffix="Min"
            oninput="javascript: if (this.value > 60) this.value = 60;">
          </v-text-field>
        </template>
      </v-slider>
    </div>
  </v-card-text>
</template>

<script>

export default {
  model: {
    prop: 'time',
    event: 'changed'
  },
  props: ['title', 'timeArr', 'time'],
  data() {
    return {
      customTimeShow: false,
      customTime: 0,
      inputTime: 0
    }
  },
  methods: {
    showCustomTime() {
      if (!parseInt(this.customTime, 10) && this.customTimeShow) this.customTimeShow = false;
    },
    includeCustomTime () {
      return [document.querySelector('.included-custom-time')];
    },
  },
  watch: {
    customTime() {
      this.$emit('changed', this.customTime * 60);
    }
  },
  mounted() {
    this.customTime = this.time / 60;
    if (this.timeArr, this.timeArr.includes(this.time)) this.inputTime = this.time;
    else if (this.time > 0) {
      this.customTimeShow = true;
    } else {
      this.inputTime = -1;
    }
  }
}
</script>

<style lang="sass">
  .tomato-time-picker-wrwap
    .v-text-field__suffix
      padding-left: 0 !important
</style>