import mutations from './mutations';

const state = {
  snackbarVis: false,
  snackbarType: 'error',
  snackbarCon: '',
};

export default { 
  namespaced: true,
  state,
  mutations
}