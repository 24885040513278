<template>
  <v-subheader>
    <v-dialog
      v-model="showDialog">
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on">
          {{ _.isNull(selectedTask) ? '无' : selectedTask.text }}
          <v-icon
            :class="{ 'arrow-down': !_.isNull(selectedTask) || showDialog }"
            size="16">
            keyboard_arrow_right
          </v-icon>
        </span>
      </template>
      <task-selector @selectTask="selectTask" />      
    </v-dialog>
  </v-subheader>
</template>

<script>
import TaskSelector from '@/components/Task/TaskSelector.vue';
import { mapState } from 'vuex';

export default {
  components: { TaskSelector },
  data() {
    return {
      showDialog: false,
    }
  },
  computed: {
    ...mapState('timer', ['ongoingTimer', 'ongoingTask']),
    selectedTask() {
      return this.$store.state.timer.ongoingTask;
    }
  },
  methods: {
    selectTask(selectedTask) {
      this.showDialog = false;
      this.$store.dispatch("timer/selectTask", selectedTask);
      if (!this._.isNull(this.ongoingTimer)) {
        this.$store.commit('timer/UPDATE_TIMER', { timer: { task: this.ongoingTask } });
        this.$store.dispatch('timer/updateTimer', this._.assign(this.ongoingTimer, { duration: -1 }));
      }
    }
  }
}
</script>