<template>
  <v-list-item
    @mouseenter="showControls(index)"
    @mouseleave="hideControls()">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          class="checkbox-wrapper">
          <v-checkbox
            :ripple="false"
            :hide-details="true"
            :color="checkboxStatu && 'grey' || 'primary'"
            class="my-0 py-0"
            v-model="checkboxStatu"
            @click="toggleDone(task)">
          </v-checkbox>
        </div>
      </template>
      <span>标记为已完成</span>
    </v-tooltip>
    <v-list-item-content
      :class="checkboxStatu && 'grey--text' || 'primary--text'"
      class="py-0">
      <span class="task-item-text"></span>
      {{ task.text }}
    </v-list-item-content>
    <v-spacer></v-spacer>
    <div class="task-item-buttons"
      v-show="active == index">
      <task-item-controls
        :tid="task.id"
        :description="task.text"
        @clickOnEditTask="editTask(task)">
      </task-item-controls>
    </div>
    <task-item-date-chip
      v-if="$attrs.showDate && task.date"
      :date="task.date"
      @clickOnMove="moveTask(task, $event)">
    </task-item-date-chip>
    <task-item-tag
      class="ml-2"
      v-for="item in task.tag"
      :key="task.id + item.text + item.color"
      :color="item.color"
      @clickOnItemTag="pushFiltedTags(item)">
      {{ item.text }}
    </task-item-tag>
    <task-item-progress
      :usedSec="task.usedSec"
      :estSec="task.estSec">
    </task-item-progress>
  </v-list-item> 
</template>

<script>
import Vue from 'vue';
import TaskItemProgress from './TaskItemProgress.vue';
import TaskItemControls from './TaskItemControls.vue';
import TaskItemTag from './TaskItemTag.vue';
import TaskItemDateChip from './TaskItemDateChip.vue';
import Bus from '@/utils/bus';
import { tagMixin } from '@/utils/mixin';

export default Vue.extend({
    props: ['task', 'index'],
    components: { TaskItemProgress, TaskItemControls, TaskItemTag, TaskItemDateChip },
    mixins: [tagMixin],
    data() {
      return {
        disabled: false,
        active: -1,
        isMenuShow: false,
        checkboxStatu: false,
        // tasks: null,
      }
    },
    methods: {
      showControls(index) {
        this.isMenuShow ? '' : this.active = index;
      },
      hideControls() {
        this.isMenuShow ? '' : this.active = -1;
      },
      menuShow(isMenuShow, index) {
        this.isMenuShow = isMenuShow;
        if (isMenuShow) {
          this.active = index;
        } else {
          this.hideControls();
        }
      },
      editTask(task) {
        Bus.$emit('editTask', task);
      },
      async toggleDone(task) {
        await new Promise((resolve) => {
          setTimeout(() => {
            task.done = this.checkboxStatu;
            resolve();
          }, 500);
        });
        this.$store.dispatch('tasks/updateTask', task);
      },
      async moveTask(task, target) {
        switch (target) {
          case 'today':
            task.date = this.dayjs().unix();
            break;
        
          default:
            break;
        }
        await this.$store.dispatch('updateTask', task);
      }
    },
    computed: {
      progress() {
        return function(index) {
          if (!this.tasks) return;
          return this.tasks[index].doneSeconds / this.tasks[index].estimatedSeconds * 100;
        }
      },
      filtedTags() {
        return this.$store.state.tasks.filtedTags;
      },
    },
    mounted() {
      this.checkboxStatu = this.$props.task.done;
    }
    
    
})
</script>

<style lang="sass" scoped>
  .v-list-item__content.grey--text   
    flex: initial
    position: relative

    &:before
      content: ''
      position: absolute
      top: 50%
      left: 0
      display: block
      width: 0%
      height: 1px
      background: var(--v-grey-base)
      animation: strike .3s ease-out 0s forwards

  @keyframes strike
    to 
      width: calc(100% + 1rem)
</style>