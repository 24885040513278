<template>
  <v-row
    class="flex-column align-center py-10">
    <v-icon
      :size="iconSize"
      color="grey">
      mdi-calendar-blank-outline
    </v-icon>
    <div 
      class="empty-hint grey--text mt-5 text-center">
      <div
        class="text-h6"
        v-text="`${$attrs.tabLabel.toUpperCase()}无任务`">
      </div>
      <v-btn
        text
        color="grey"
        @click="emit">
        添加任务
        <v-icon
          size="20">
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
  </v-row>
</template>

<script>
import Vue from 'vue';
import Bus from '@/utils/bus';

export default {
  props: ['tabs', 'tab'],
  computed: {
    iconSize() {
      const { xs } = this.$vuetify.breakpoint;
      return xs ? 84 : 128;
    },
    tasks() {
      return this.$store.state.tasks.tasks;
    },
    hasUnfinishedTask() {
      return !Vue.filter('unfinishedTasks', this.tasks).length;
    }
  },
  methods: {
    emit() {
      Bus.$emit('clickOnAddTask');
    }
  },
}
</script>