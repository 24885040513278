<template>
  <v-container
  :style="padding"
  class="account-container">
    <v-row
      class="justify-center align-center mt-10">
      <v-col
        :cols="cols">
        <v-slide-x-transition
          mode="out-in">
          <router-view></router-view>
        </v-slide-x-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    cols() {
      const { xl, lg, md, sm, xs } = this.$vuetify.breakpoint,
            baseCol = 4,
            increa = 2;
      return xl ? baseCol : lg ? baseCol + increa * 1 : md ? baseCol + increa * 2 : sm ? baseCol + increa * 3 : xs ? baseCol + increa * 4 : ''; 
    },
    padding() {
      const { xs }  = this.$vuetify.breakpoint;
      return {
        '--title-padding': xs ? `${40}px`  : `${60}px`
      }
    }
  }
}
</script>

<style lang="sass">
  .account-container

    .v-card__subtitle, .v-card__text, .v-card__title
      padding: var(--title-padding)
</style>