<template>
  <v-list-item-icon
    class="flex-row-reverse ml-2">
    <v-tooltip top>
      <template #activator="{ on }">
        <span
          v-on="on"
          class="progress-wrapper">
          <v-progress-circular 
            :value="progress"
            :rotate="360"
            :width="9"
            :size="18"
            color="green">
          </v-progress-circular>
        </span>
      </template>
      <span>{{ remainTime }}</span>
    </v-tooltip>
  </v-list-item-icon>
</template>

<script>
import Vue from 'vue';
// import { apiConfig } from '../utils/api';

export default Vue.extend({
  props: ['usedSec', 'estSec'],
  computed: {
    remainTime() {
      if (this.$props.estSec === 0) {
        return '没有设置时间';
      } else {
        const sec = this.$props.estSec - this.$props.usedSec;
        const hour = parseInt(sec / 3600, 10);
        const min = Math.abs(parseInt((hour * 3600 - sec) / 60 , 10));
        return `剩余时间: ${hour ? hour + '小时' : ''}${min ? min + '分钟' : ''}`
      }
    },
    progress() {
      return parseInt(this.$props.usedSec / this.$props.estSec);
    }
  }
})
</script>

<style lang="sass" scoped>
  .v-list-item__icon
    margin: 12px 0
    position: relative
    min-width: auto !important

    span.progress-wrapper
      

      .v-progress-circular
        position: relative
        top: -2px
  
</style>