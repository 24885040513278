<template>
  <v-card
    :style="cssProps">
    <v-card-title
      class="primary--text pb-10">
      用户登录
    </v-card-title>
    <v-card-text
      class="pt-0">
      <validation-observer
        v-slot="{ handleSubmit }"
        ref="form">
        <v-form
          @submit.prevent="handleSubmit(submit)">
          <validation-provider
            name="邮箱"
            vid="email"
            rules="required|email"
            v-slot="{ errors }"
            autocomplete="off" 
            autocorrect="off" 
            autocapitalize="off" 
            spellcheck="false">
            <v-text-field
              label="邮箱"
              v-model="email"
              :error-messages="errors"
              outlined>
            </v-text-field>
          </validation-provider>
          <validation-provider
            name="密码"
            vid="password"
            rules="required|min:8|max:100"
            v-slot="{ errors }">
            <v-text-field
              label="密码"
              outlined
              v-model="password"
              :error-messages="errors"
              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPwd ? 'text' : 'password'"
              @click:append="showPwd = !showPwd"
              @focus="showEye = true"
              @blur="showEye = false; showPwd = false"
              autocomplete="off" 
              autocorrect="off" 
              autocapitalize="off" 
              spellcheck="false">
            </v-text-field>
          </validation-provider>
          <v-row>
          </v-row>
          <v-list-item
            class="px-0 mb-5">
            <v-checkbox
              class="mt-0"
              hide-details>
              <template v-slot:label>
                记住我
              </template>
            </v-checkbox>
            <v-spacer></v-spacer>
            <a href=""
              color="primary"
              @click.prevent="$emit('clickOnToReset')">
              忘记密码?
            </a>
          </v-list-item>
          <v-list-item
            class="px-0">
            <v-btn
              width="100%"
              color="primary"
              type="submit">
              登录
            </v-btn>
          </v-list-item>
        </v-form>
      </validation-observer>
      <v-list-item
        class="px-0">
        <v-spacer></v-spacer>
        <router-link to="/register">注册账号&nbsp;»</router-link>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      showPwd: false,
      showEye: false,
      email: '',
      password: '',
    }
  },
  computed: {
    cssProps() {
      return {
        '--show-eye-vis': this.showEye ? 'visible' : 'hidden',
        '--show-eye-opa': this.showEye ? '1' : '0'
      }
    },
  },
  methods: {
    // Login success
    // "{"type":"data","_data":{"uid":18,"token":"ABVhR7DAgWZ0rBFB8YIG+Gyq6zoPZvkrfEoxStd7AIsQNsxIrpYhl8PeKX83hvqW","user":{"id":18,"username":"fronz","nickname":"fronz","email":"","passwd":"","settings":"{}"}}}"
    // Errors
    // "{"type":"error","_data":"user_not_found"}"
    // "{"type":"error","_data":"wrong_passwd"}"
    submit() {
      this.$store.commit('snackbar/HIDE');
      this.$store.dispatch('account/login', {
        email: this.email,
        passwd: this.password
      }).then(res => {
        if (res.type === 'error') {
          const errMsg = {
            'user_not_found': { email: ['用户不存在'] },
            'email_not_verified': { email: ['邮箱未验证'] },
            'wrong_passwd': { password: ['密码错误'] },
          };
          this.$refs.form.setErrors(errMsg[res.error]);
        } else if (res.type === 'data') {
          this.$store.commit('snackbar/SHOW', {
            type: 'success',
            content: '登录成功!'
          });
          this.$router.push('timer');
          this.email = '';
          this.password = ''; 
        }
      }).catch(err => {
        this.$store.commit('snackbar/SHOW', {
          type: 'error',
          content: err
        });
      }); 
    }
  }
}
</script>

<style lang="sass">
  .account-container
    .v-input__append-inner
      visibility: var(--show-eye-vis)
      opacity: var(--show-eye-opa)
      transition: visibility 0s, opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
</style>