<template>
  <div class="task-list">
    <task-empty-hint
      v-if="showHint && !rangeTasks.length"
      v-bind="$attrs">
    </task-empty-hint>
    <v-list>
      <task-item
        v-for="(task, index) in unfinishedTasks"
        :task="task"
        :key="task.id"
        :index="index"
        v-bind="$attrs">
      </task-item>
    </v-list>
    <v-fade-transition>
      <task-finished-list
        v-if="finishedTasksLength"
        :range="range"
        class="pt-0">
      </task-finished-list>
    </v-fade-transition>
  </div>
</template>

<script>
import Vue from 'vue';
import TaskItem from './TaskItem.vue';
import TaskFinishedList from './TaskFinishedList.vue';
import TaskEmptyHint from './TaskEmptyHint.vue';
import Bus from '@/utils/bus';
import { tasksMixin } from '@/utils/mixin';


export default Vue.extend({
  props: ['range', 'showHint'],
  components: { TaskItem, TaskFinishedList, TaskEmptyHint },
  mixins: [tasksMixin],
  mounted() {
    const vm = this;
    Bus.$on('addTaskDone', () => {
      vm.$forceUpdate();
    });
    Bus.$on('removeFiltedTag', () => {
      vm.filterByTag;
      vm.$forceUpdate();
    });
  }
})
</script>
