<template>
  <div class="task-time-picker-wrap">
    <v-menu
      :close-on-content-click="false"
      attach=".task-time-picker-wrap"
      offset-y
      nudge-bottom="5"
      nudge-right="1">
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          small
          v-bind="attrs"
          v-on="on">
          <v-icon
            size="16"
            class="mr-1"
            left>
            mdi-clock-time-eight
          </v-icon>
          {{ taskTimeInputSec | formatSec }}
        </v-chip>
      </template>
      <div>
        <div class="task-time-input-wrap">
          <v-text-field
            :value="hour"
            @input="setNewVal({ newHour: $event })"
            @focus="hideSelectedBack = true"
            class="py-0 task-time-input"
            dense
            solo
            flat
            background-color="white"
            hide-details
            suffix="h"
            maxlength="2"
            pattern="\d*"
            type="number"
            oninput="javascript: if (this.value > 23) this.value = 23;">
          </v-text-field>
          <v-text-field
            :value="min"
            @input="setNewVal({ newMin: $event })"
            @focus="hideSelectedBack = true"
            class="py-0 task-time-input"
            dense
            solo
            flat
            background-color="white"
            hide-details
            suffix="m"
            maxlength="2"
            pattern="\d*"
            type="number"
            oninput="javascript: if (this.value > 59) this.value = 59;">
          </v-text-field>
        </div>
        <v-divider></v-divider>
        <v-list
          class="py-0"
          dense>
          <v-list-item-group
            v-model="taskTimeSelectModel">
            <v-list-item
              dense
              :active-class="hideSelectedBack ? 'hide-backgound' : ''"
              @click="hideSelectedBack = false"
              v-for="time in timeArray"
              :key="time">
              <v-list-item-title>
                {{ timeStr(time) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'estSec',
    event: 'changed'
  },
  props: {
    estSec: {
      type: Number,
    }
  },
  data() {
    return {
      timeArray: [60, 90, 120],
      taskTimeInputHourModel: 0,
      taskTimeInputMinModel: 0,
      taskTimeInputSec: 0,
      limitedMin: 1439,
      hideSelectedBack: false
    }
  },
  filters: {
    formatSec(sec) {
      if (!sec) return '用时';
      const hour = parseInt(sec / 3600, 10),
            min = parseInt(sec % 3600 / 60, 10);
      return `${hour}h ` + (min ? `${min}m` : '');
    }
  },
  computed: {
    timeStr() {
      return (time) => {
        return `${parseInt(time / 60, 10)}h ` + (time % 60 ? `${time % 60}m` : '');
      }
    },
    taskTimeSelectModel: {
      get: '',
      set: function(taskTimeSelectModel) {
        const hour = parseInt(this.timeArray[taskTimeSelectModel] / 60, 10),
              min = this.timeArray[taskTimeSelectModel] % 60;
        this.setNewVal({ newHour: hour, newMin: min });
      }
    },
    hour() {
      return parseInt(this.taskTimeInputSec / 3600, 10);
    },
    min() {
      return parseInt(this.taskTimeInputSec % 3600 / 60, 10);
    }
  },
  watch: {
    estSec() {
      this.taskTimeInputHourModel = parseInt(this.estSec / 3600, 10);
      this.taskTimeInputMinModel = parseInt(this.estSec % 3600 / 60, 10);
      this.taskTimeInputSec = this.estSec;
    },
    taskTimeInputSec() {
      this.$emit('changed', this.taskTimeInputSec);
    },
  },
  methods: {
    setNewVal({ newHour, newMin }) {
      if (!(newHour === undefined)) this.taskTimeInputHourModel = isNaN(newHour) ? 0 : newHour;
      if (!(newMin === undefined)) this.taskTimeInputMinModel = isNaN(newMin) ? 0 : newMin; 
      this.taskTimeInputSec = this.taskTimeInputHourModel * 3600 + this.taskTimeInputMinModel * 60;
    },
  },
  mounted() {
    this.taskTimeInputHourModel = parseInt(this.$props.estSec / 3600, 10);
    this.taskTimeInputMinModel = parseInt(this.$props.estSec % 3600 / 60, 10);
    this.taskTimeInputSec = this.$props.estSec;
  }
}
</script>

<style lang="sass">
  .task-time-picker-wrap
    .hide-backgound::before
      opacity: 0

    .v-menu__content
      background-color: white

    .task-time-input-wrap
      display: flex

      .task-time-input
        max-width: 52px !important
        

        &:last-child .v-input__slot
          padding: 0 16px 0 0 !important

        .v-input__control
          .v-input__slot
            .v-text-field__suffix
              margin-top: 1px
</style>