<template>
  <div
    class="scroll-picker-wrap"
    :class="{ 'hover': hover }"
    style="position: relative">
    <scroll-picker
      v-model="sec"
      :options="secArr"
      :scrollSensitivity="0.2">
    </scroll-picker>
    <span
      class="scroll-picker-nav right">
      <v-icon
        @click="plus">
        mdi-plus
      </v-icon>
    </span>
    <span
      class="scroll-picker-nav left">
      <v-icon
        @click="minus">
        mdi-minus
      </v-icon>
    </span>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { ScrollPicker } from 'vue-scroll-picker';
import { mapState, mapGetters } from 'vuex';

const formatMin = (min) => {
  const res = min ? (min < 10 ? `0${String(min)}` : String(min)) : '00';
  return res + ':';
}

export default {
  model: {
    prop: 'inputSec',
    event: 'changed'
  },
  props: {
    inputSec: {
      type: Number,
      default: 1500
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  components: { ScrollPicker },
  data() {
    return {
      sec: 1500,
      secArr: []
    }
  },
  filters: {
    formatSec(sec) {
      const min = parseInt(dayjs.duration(sec, 's').as('m'), 10);
      const formatedSec = dayjs.duration(sec, 's').format('ss');
      return formatedSec ? formatMin(min) + formatedSec : dayjs.duration(sec, 's').format('mm:ss');
    }
  },
  computed: {
    ...mapState('account', {
      lastUsedDefaultTomatoTime: (state) => state.user.settings.lastUsedDefaultTomatoTime,
    }),

    ...mapState('timer', {
      ongoingTaskTomatoTime: (state) => state.ongoingTask?.setting.tomatoTime
    }),
    
    ...mapGetters('timer', ['hasOngoingTask'])
  },
  methods: {
    minus() {
      if (this.sec > 300) this.sec -= 300;
      else this.sec = 3600;
    },
    plus() {
      this.sec += 300;
    }
  },
  watch: {
    sec() {
      this.$store.commit('timer/CHANGE_TOTAL', { newValue: this.sec });
    },
    ongoingTaskTomatoTime() {
      if (this.hasOngoingTask) {
        this.sec = this.ongoingTaskTomatoTime;
      }
    }
  },
  beforeMount() {
    const increment = 5;

    if (!this.hasOngoingTask) this.sec = this.lastUsedDefaultTomatoTime;
    else this.sec = this.ongoingTaskTomatoTime;

    this.secArr = Array(3600 / 300).fill()
      .map((v, i) => Object({
        value: (i + 1) * 300,
        name: `${(i + 1) * increment < 10 ? '0' + String((i + 1) * 5) : String((i + 1) * 5)}:00`
      }));

    // Test
    this.secArr.push({
      value: 10,
      name: '00:10'
    });
  },
}
</script>

<style lang="sass">
  .scroll-picker-wrap
    .scroll-picker-nav
      position: absolute
      top: calc( 50% - 12px )
      visibility: hidden
      opacity: 0
      
      &.right
        margin-right: -32px
        right: 0

      &.left
        margin-left: -32px
        left: 0

    .vue-scroll-picker
      overflow: hidden

      .vue-scroll-picker-list
        height: 160px

        .vue-scroll-picker-item
          font-size: 32px

        .vue-scroll-picker-list-rotator
          position: relative

      .vue-scroll-picker-layer
        position: absolute
        left: 0
        right: 0
        top: 0
        bottom: 0

        .top, .middle, .bottom
          position: absolute

        .top
          top: 0
          left: 0
          right: 0
          height: calc(50% - 1em)
          overflow: hidden
          background: #ffffff

        .middle
          top: calc(50% - 1em)
          left: 0
          right: 0
          bottom: calc(50% - 1em)

        .bottom
          bottom: 0
          left: 0
          right: 0
          height: calc(50% - 1em)
          overflow: hidden
          background: #ffffff

    &.hover
      .scroll-picker-nav, .top, .bottom
        transition: all 0.1s linear

      .scroll-picker-nav
        visibility: visible !important
        opacity: 1 !important

      .top
        background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.7)) !important

      .bottom
        background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.7)) !important

</style>