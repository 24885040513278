<template>
  <v-container 
    class="timer-container pa-6">
    <!-- <v-row>
      <v-col>
        <v-card height="12rem">
          <v-card-subtitle>时间线</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <timer-status></timer-status>
      </v-col>
      <!-- <v-col>
        <v-card width="100%" height="100%">
          
        </v-card>
      </v-col> -->
    </v-row>
    <v-row
      v-for="(timers, key) in sortedTimers"
      :key="key">
      <v-col>
        <timer-list-item-container
          :timers="timers"
          :date="key">
      </timer-list-item-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import TimerChart from './TimerChart.vue';
import TimerListItemContainer from './TimerListItemContainer.vue';
import TimerStatus from './TimerStatus.vue';
import { mapState } from 'vuex';

export default {
  components: { TimerStatus, TimerListItemContainer },
  computed: {
    ...mapState('timer', [
      'timers'
    ]),

    sortedTimers() {
      return this.timers?.reduce((acc, cur) => {
        if (cur.duration === -1) return acc;
        const curDate = this.dayjs(cur.start).format('YYYY-MM-DD');
        if (curDate in acc)
          acc[curDate].push(cur);
        else
          acc[curDate] = [cur];
        return acc;
      }, {});
    }
  },
}
</script>