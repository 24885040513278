<template>
  <v-menu
    v-model="show"
    offset-y>
    <template #activator="{ on }">
      <v-chip
        v-on="on"
        small
        class="custom-chip">
        {{ dayjs.unix(date).format('MM-DD') }}
      </v-chip>
    </template>
    <v-list
      v-if="!dayjs.unix(date).isSame(dayjs(), 'day')"
      dense>
      <v-list-item
        @click="moveToToday"
        class="text-body-2"
        dense>
        移动至今日
      </v-list-item>
    </v-list>
  </v-menu>
  
</template>

<script>
export default {
  model: {
    prop: 'task',
    event: 'changed'
  },
  props: ['date', 'task'],
  data() {
    return {
      show: false,
      editingTask: null
    }
  },
  methods: {
    async moveToToday() {
      this.$emit('clickOnMove', 'today');
    }
  },
  beforeMount() {
    this.editingTask = this.$props.task;
  }
}
</script>