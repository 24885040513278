import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';
import Vue from 'vue';

dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

Vue.prototype.dayjs = dayjs;

export default dayjs;
