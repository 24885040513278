<template>
  <v-avatar
    :size="size"
    color="primary--light">
    <span 
      class="white--text headline">
      {{ $store.state.account.user.nickname.slice(0, 1) }}
    </span>
    <img v-if="hasAvatar" src="https://pic.rmb.bdstatic.com/bjh/8c9caa5cdec427981c736682437266e1.jpeg" alt="">
  </v-avatar>
</template>

<script>
export default {
  props: ['size'],
  data() {
    return {
      hasAvatar: false
    }
  }
}
</script>