var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"absolute":"","nudge-bottom":15,"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[_vm._l((_vm.selectedTags),function(tag){return _c('span',_vm._g(_vm._b({key:tag.id,staticClass:"mr-2"},'span',attrs,false),on),[_c('task-item-tag',{attrs:{"color":tag.color}},[_vm._v(" "+_vm._s(tag.text)+" ")])],1)}),(_vm.selectedTags.length !== 0)?_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"size":"16"}},on),[_vm._v(" mdi-pencil ")]):_vm._e()],2)]}}],null,true)}),_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTags.length === 0),expression:"selectedTags.length === 0"}],attrs:{"small":""}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16"}},[_vm._v(" mdi-tag ")]),_vm._v(" 标签 ")],1)]}}])},[_c('v-card',{staticClass:"task-tag-picker"},[_c('v-card-text',[_c('v-text-field',{attrs:{"dense":"","hide-details":"","autofocus":"","outlined":"","autocomplete":"off","placeholder":"查找或创建一个标签"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTag($event)}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('v-divider'),_c('v-list',{staticClass:"pt-2 pb-0"},[_vm._l((_vm.filtedTags),function(tag){return _c('v-hover',{key:tag.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[(_vm.renamingTag === tag)?_c('v-list-item',{staticClass:"px-3",staticStyle:{"min-height":"40px","min-width":"220px"}},[_c('v-text-field',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.renameFinish),expression:"renameFinish"}],staticClass:"text-body-2",attrs:{"autofocus":"","background-color":"transparent","hide-details":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.renameFinish($event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{on:{"click":_vm.renameFinish}},[_vm._v(" mdi-check ")])]},proxy:true}],null,true),model:{value:(_vm.renamingText),callback:function ($$v) {_vm.renamingText=$$v},expression:"renamingText"}})],1):_c('v-list-item',{staticClass:"px-3",staticStyle:{"min-width":"220px"},attrs:{"ripple":false,"dense":"","link":""}},[_c('v-list-item-action',{staticClass:"mr-2 ml-0 my-0"},[_c('v-checkbox',{attrs:{"value":tag,"hide-details":""},on:{"change":function($event){return _vm.$emit('changed', _vm.selectedTags)}},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1),_c('v-list-item-content',[_c('span',[_vm._v(" "+_vm._s(tag.text)+" ")])]),_c('v-list-item-action',{class:{ 'active': hover },staticStyle:{"min-width":"initial","visibility":"hidden"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"16"}},on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item-group',_vm._l((_vm.tagOperations),function(opea){return _c('v-list-item',{key:opea.name,staticClass:"text-body-2",attrs:{"dense":""},on:{"click":function($event){return opea.func(tag)}}},[_c('v-list-item-icon',{staticClass:"ml-0"},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(opea.icon)+" ")])],1),_vm._v(" "+_vm._s(opea.name)+" ")],1)}),1)],1)],1)],1)],1)],1)]}}],null,true)})}),(_vm.allowCreate || (_vm.allTags.length === 0 && _vm.text.length > 0))?_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":_vm.addTag}},[_c('v-list-item-icon',{staticClass:"mx-0"},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-tag-plus ")])],1),_c('span',{staticClass:"text-body-2"},[_vm._v(" 创建 "),_c('task-item-tag',{staticClass:"ml-1",attrs:{"color":_vm.color}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)],1):(_vm.allTags.length === 0)?_c('div',{staticClass:"d-flex grey--text justify-center"},[_vm._v(" 创建你的第一个标签 :) ")]):_vm._e()],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }