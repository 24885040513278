<template>
  <timer-container></timer-container>
</template>

<script>
import TimerContainer from '@/components/Timer/TimerContainer.vue';

export default {
  components: { TimerContainer },
} 
</script>