var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"primary--text pb-10"},[_vm._v(" 注册账号 ")]),_c('v-card-text',{staticClass:"pt-0"},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"name":"用户名","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"用户名","error-messages":errors,"outlined":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"邮箱","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"邮箱","type":"email","error-messages":errors,"outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"密码","rules":"required|min:8|max:100","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"密码","type":"password","error-messages":errors,"outlined":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"确认密码","rules":"confirmed:password|emptyConfirm","mode":_vm.confirmPassword},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"确认密码","type":"password","error-messages":errors,"outlined":""},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})]}}],null,true)}),_c('v-list-item',{staticClass:"px-0"},[_c('v-btn',{attrs:{"width":"100%","color":"primary","type":"submit"}},[_vm._v(" 注册 ")])],1)],1)]}}])}),_c('v-list-item',{staticClass:"px-0"},[_c('router-link',{attrs:{"to":"/login"}},[_vm._v("« 返回登录")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }