import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import accountModule from './modules/account';
import tasksModule from './modules/tasks';
import timerModule from './modules/timer';
import snackbarModule from './modules/snackbar';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loadding: true,
    tabIndex: 0
  },
  mutations,
  modules: {
    account: accountModule,
    tasks: tasksModule,
    timer: timerModule,
    snackbar: snackbarModule
  }
})

Object({
  type: 'div',
  props: {
      name: 'lucifer'
  },
  children: [{
      type: 'span',
      props: {},
      children: []
  }]
});