const SHOW = (state, { type, content }) => {
  state.snackbarType = type;
  state.snackbarCon = content;
  state.snackbarVis = true;
};

const HIDE = (state) => {
  state.snackbarVis = false;
};

export default {
  SHOW,
  HIDE
}