<template>
  <v-dialog
    v-model="showSetting">
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon
          color="white">
          settings
        </v-icon>
      </v-btn>
    </template>
    <v-row
      class="elevation-0">
      <v-col
        class="d-flex justify-center">
        <v-card
          width="24rem">
          <v-card-subtitle>
            用户设置
          </v-card-subtitle>
          <v-card-text>
                <div>
                  <v-row>
                    <v-col
                      class="d-flex justify-center">
                      <avatar
                      :size="96">
                      </avatar>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        outlined
                        dense
                        label="昵称"
                        hide-details
                        v-model="newNickname">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col>
                    <v-btn
                      color="error"
                      @click="logout">
                      注销
                    </v-btn>
                  </v-col>
                </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showSetting = false">
              关闭
            </v-btn>
            <v-btn
              text
              :disabled="nickname === newNickname"
              @click="saveUserInfo">
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import Avatar from './Avatar.vue';
import { mapState } from 'vuex';

export default {
  components: { Avatar },
  data() {
    return {
      showSetting: false,
      tabs: ['general','account'],
      tabIndex: 0,
      newNickname: '',
    }
  },
  methods: {
    logout() {
      this.$store.commit('account/CLEAR_TOKEN');
      this.$store.commit('snackbar/SHOW', {
        type: 'success',
        content: '注销成功!'
      });
      this.$router.push('login');
    },
    saveUserInfo() {
      this.showSetting = false;
      this.$store.dispatch('account/updateUserInfo', {
        nickname: this.newNickname
      }).then(res => {
        this.$store.commit('snackbar/SHOW', {
          type: 'success',
          content: '保存成功！'
        });
      });
    }
  },
  computed: {
    ...mapState('account', {
      nickname: state => state.user.nickname
    })
  },
  watch: {
    showSetting() {
      if (this.showSetting) {
        this.newNickname = this.nickname;
        console.log(this.newNickname, this.nickname);
      }
    }
  }
}
</script>