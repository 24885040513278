<template>
  <v-row
    class="justify-center elevation-0">
    <v-col
      cols="4">
      <v-card>
        <v-card-subtitle>
          选择一个任务
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="filtedKey"
            placeholder="查找..."
            hide-details
            outlined
            dense>
            <task-item-tag
              class="ml-2"
              slot="append"
              v-for="tag in filtedTags"
              :key="tag.text"
              :color="tag.color"
              :showClose="true"
              @clickOnItemTagClose="removeTag(tag)">
              {{ tag.text }}
            </task-item-tag>
          </v-text-field>
          <v-radio-group
            hide-details
            v-if="filtedTasksByKey.length"
            v-model="radioIndex">
            <v-radio label="无"></v-radio>
            <v-radio
              v-for="task in filtedTasksByKey"
              :key="task.id">
              <template v-slot:label>
                <div
                  class="d-flex justify-space-between flex-grow-1 align-center">
                  <span>{{ task.text }}</span>  
                </div>
                <div>
                  <task-item-tag
                    class="ml-2"
                    v-for="tag in task.tag"
                    :key="task.id + tag.text + tag.color"
                    :color="tag.color"
                    @clickOnItemTag="pushFiltedTags(tag)">
                    {{ tag.text }}
                  </task-item-tag>
                  <task-item-progress
                    :usedSec="task.usedSec"
                    :estSec="task.estSec">
                  </task-item-progress>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <div
            v-else-if="!filtedTasksByKey.length && unfinishedTasks.length"
            class="pt-8"
            style="text-align: center">
            无结果
          </div>
          <div 
            v-else
            class="pt-8"
            style="text-align: center">
            无任务
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="selectTask()">
              确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import TaskItemTag from '@/components/Task/TaskItemTag.vue';
import TaskItemProgress from '@/components/Task/TaskItemProgress.vue';
import { mapState, mapGetters } from 'vuex';
import { tasksMixin } from '@/utils/mixin';

export default {
  components: { TaskItemTag, TaskItemProgress },
  mixins: [tasksMixin],
  data() {
    return {
      filtedKey: '',
      radioIndex: undefined,
      range: this.dayjs(),
      selectedTask: null,
    }
  },
  computed: {
    ...mapGetters('tasks', ['tasks']),
    filtedTasksByKey() {
      return this.unfinishedTasks.filter(task => this._.includes(task.text.toLowerCase(), this.filtedKey.toLowerCase()));
    },
    ...mapState('tasks', ['filtedTags'])
  },
  watch: {
    radioIndex() {
      this.selectedTask = this.radioIndex > 0 ? this.filtedTasksByKey[this.radioIndex - 1] : null;
    }
  },
  methods: {
    pushFiltedTags(tag) {
      if (this.hasEqualTag(tag)) return;
      this.filtedTags.push(tag);
    },
    hasEqualTag(tag) {
      if (this._.isEmpty(this.filtedTags)) return false;
      const filtedTagsText = this.filtedTags.map(tag => tag.text);
      return filtedTagsText.includes(tag.text);
    },
    removeTag(tag) {
      this.$store.commit('tasks/REMOVE_FILTER_TAG', {
        tag: tag
      });
    },
    selectTask() {
      this.$emit('selectTask', this.selectedTask);
    }
  },
}
</script>

<style lang="sass" scoped>
  .v-list-item__icon
    margin: 0 0 0 8px !important

  .arrow-down
    transform: rotate(90deg)
</style>