<template>
  <div>
    <v-menu
      absolute
      :nudge-bottom="15"
      offset-y
      :close-on-content-click="false">
      <template #activator="{ on, attrs }">
        <v-hover
          v-slot="{ hover }">
          <div>
            <span
              class="mr-2"
              v-for="tag in selectedTags"
              :key="tag.id"
              v-on="on"
              v-bind="attrs">
              <task-item-tag 
                :color="tag.color">
                {{ tag.text }}
              </task-item-tag>
            </span>
            <v-icon
              v-on="on"
              v-show="hover" 
              v-if="selectedTags.length !== 0"
              size="16">
              mdi-pencil
            </v-icon>
          </div>
        </v-hover>
        <v-chip
          v-show="selectedTags.length === 0"
          small
          v-bind="attrs"
          v-on="on">
          <v-icon
            size="16"
            class="mr-1">
            mdi-tag
          </v-icon>
          标签
        </v-chip>  
      </template>
      <v-card
        class="task-tag-picker">
        <v-card-text>
          <v-text-field
            v-model="text"
            dense
            hide-details
            autofocus
            outlined
            autocomplete="off"
            placeholder="查找或创建一个标签"
            @keyup.enter="addTag">
          </v-text-field>
          <v-divider></v-divider>
          <v-list
            class="pt-2 pb-0">
              <v-hover 
                v-slot="{ hover }"
                v-for="tag in filtedTags"
                :key="tag.id">
                <div>
                  <v-list-item
                    style="min-height: 40px; min-width: 220px"
                    v-if="renamingTag === tag"
                    class="px-3">
                    <v-text-field
                      class="text-body-2"
                      autofocus
                      background-color="transparent"
                      hide-details
                      dense
                      v-model="renamingText"
                      @keyup.enter="renameFinish"
                      v-click-outside="renameFinish">
                      <template #prepend>
                        <v-icon
                          @click="renameFinish">
                          mdi-check
                        </v-icon>
                      </template>
                    </v-text-field>
                  </v-list-item>
                  <v-list-item
                    style="min-width: 220px"
                    v-else
                    :ripple="false"
                    dense
                    link
                    class="px-3">
                    <v-list-item-action
                      class="mr-2 ml-0 my-0">
                      <v-checkbox
                        :value="tag"
                        v-model="selectedTags"
                        @change="$emit('changed', selectedTags)"
                        hide-details>
                      </v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <span>
                        {{ tag.text }}
                      </span>
                    </v-list-item-content>
                    <v-list-item-action
                      :class="{ 'active': hover }"
                      style="min-width: initial; visibility: hidden">
                      <v-menu
                        offset-y>
                        <template #activator="{ on }">
                          <v-icon
                            size="16"
                            v-on="on">
                            mdi-dots-vertical
                          </v-icon>
                        </template>
                      <v-list
                        class="py-0">
                        <v-list-item-group>
                          <v-list-item
                            class="text-body-2"
                            v-for="opea in tagOperations"
                            :key="opea.name"
                            @click="opea.func(tag)"
                            dense>
                            <v-list-item-icon
                              class="ml-0">
                              <v-icon
                                size="18">
                                {{ opea.icon }}
                              </v-icon>
                            </v-list-item-icon>
                            {{ opea.name }}
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-hover>
              <v-list-item
                link
                dense
                v-if="allowCreate || (allTags.length === 0 && text.length > 0)"
                @click="addTag">
                <v-list-item-icon
                  class="mx-0">
                  <v-icon
                    size="18">
                    mdi-tag-plus
                  </v-icon>
                </v-list-item-icon>
                <span class="text-body-2">
                  创建
                  <task-item-tag
                    class="ml-1"
                    :color="color">
                    {{ text }}
                  </task-item-tag>
                </span>
              </v-list-item>
              <div
                v-else-if="allTags.length === 0"
                class="d-flex grey--text justify-center">
                创建你的第一个标签 :)
              </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Bus from '@/utils/bus';
import TaskItemTag from './TaskItemTag.vue';

export default {
  components: { TaskItemTag },
  model: {
    prop: 'tags',
    event: 'changed'
  },
  props: ['tags'],
  data() {
    return {
      selectedTags: [],
      renamingTag: null,
      renamingText: '',
      color: '',
      text: '',
      tagOperations: [{
        name: '重命名',
        icon: 'mdi-pencil',
        func: (tag) => this.renameTag(tag)
      }, {
        name: '删除',
        icon: 'mdi-delete',
        func: (tag) => this.deleteTag(tag)
      }]
    }
  },
  computed: {
    allTags() {
      return this._.get(this.$store.state.account, 'user.settings.tag');
    },
    filtedTags() {
      return this.allTags.filter((tag) => tag.text.toLowerCase().includes(this.text.toLowerCase()));
    },
    allowCreate() {
      return this.filtedTags.length === 0 && this.allTags.length > 0;
    },
    isRenaming() {
      return (tag) => {
        return this.renamingTag === null ? false : this.renamingTag.text === tag.text
      }
    }
  },
  methods: {
    addTag() {
      const newTag = {
        id: this.$shortid.generate(),
        color: this.color,
        text: this.text
      };

      this.selectedTags.push(newTag);
      this.$store.dispatch('account/addTag', newTag);

      this.text = '';
      this.getColor();
    },
    deleteTag(targetTag) {
      this.selectedTags = this.selectedTags.filter(tag => tag != targetTag);
      this.$store.dispatch('account/deleteTag', targetTag);
      this.$emit('changed', this.selectedTags);
    },
    renameTag(renameTag) {
      this.renamingTag = renameTag;
      this.renamingText = renameTag.text;
    },
    renameFinish() {
      if (this.renamingTag.text != this.renamingText) {
        this.renamingTag.text = this.renamingText;
        this.$store.dispatch('account/updateTag', this.renamingTag);
      }

      this.renamingTag = null;
    },
    getColor() {
      const colors = this.$store.state.account.user.settings.colors;
      const randomColor = colors[this._.random(0, colors.length - 1)];
      this.color = randomColor;
    },
  },
  mounted() {
    const initTags = (task) => {
      if (!task && this.tags) this.selectedTags = this.tags;
      else if (task) this.selectedTags = task.tag;
    }
    initTags();
    this.getColor();
    Bus.$on('TaskEditBoxShow', (task) => {
      initTags(task)
    });
    Bus.$on('TaskEditBoxClose', () => this.selectedTags = []);

  },
}
</script>

<style lang="sass">
  .task-tag-picker
    .active
      visibility: initial !important

  .v-application--is-ltr .v-input__prepend-outer
    margin-right: 8px
</style>