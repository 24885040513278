import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  tasks: null,
  filtedTags: [],
  showEditBox: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}