import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
  mode: 0,
  interval: 0,
  left: null,
  total: 1500,
  timers: null,
  ongoingTimer: null,
  ongoingTask: null
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}