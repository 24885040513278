<template>
    <v-list-item
      :class="{ 'active': active }"
      class="px-4 timer-item py-4"
      :ripple="false"
      >
      <v-chip
        v-if="isHeader"
        small
        label
        class="px-0 d-flex justify-center mr-2"
        style="width: 24px"
        @click="$emit('clickOnHeader')">
        {{ timer.timerNumber }}
      </v-chip>
      <v-hover
        v-slot="{ hover }">
        <v-list-item-content
          :class="paddingLeft ? 'pl-8' : ''"
          class="timer-item-content pr-0 py-0 justify-space-between">
          <div class="d-flex align-center justify-space-between pr-6" style="flex-grow: 1 !important">
            <span class="cursor-pointer" :class="{ 'grey--text': !!!timer.task }">{{ !!timer.task ? timer.task.text : '无' }}</span>
            <v-menu
              v-if="!isHeader">
              <template
                v-slot:activator="{ on }">
                <span
                  class="cursor-pointer"
                  :style="{ 'visibility': hover && 'visible' || 'hidden' }"
                  v-on="on">
                  <v-icon>
                    mdi-dots-horizontal
                  </v-icon>
                </span>
              </template>
              <v-list>
                <v-dialog
                  v-model="showSelectTaskDialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      link
                      dense
                      v-bind="attrs"
                      v-on="on">
                      <v-list-item-title>
                        指定
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <task-selector @selectTask="selectTask"></task-selector>
                </v-dialog>
                <v-list-item
                  @click="deleteTimer(timer.id)"
                  link
                  dense>
                  <v-list-item-title>
                    删除
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div>
            <span class="mr-12">{{ start + ' - ' + end }}</span>
            <span>{{ dayjs.duration(timer.duration, 'seconds').format('mm:ss') }}</span>
          </div>
        </v-list-item-content>
      </v-hover>
    </v-list-item>
</template>

<script>
import TaskSelector from '@/components/Task/TaskSelector.vue';

export default {
  components: { TaskSelector },
  props: ['timer', 'isHeader', 'paddingLeft', 'active'],
  data() {
    return {
      showSelectTaskDialog: false
    }
  },
  computed: {
    start() {
      return this.dayjs(this.timer.start).format('HH:mm');
    },
    end() {
      return this.dayjs(this.isHeader ? this.timer.stop : this.timer.start + this.timer.duration * 1000).format('HH:mm');
    }
  },
  methods: {
    deleteTimer(id) {
      this.$store.dispatch('timer/deleteTimer', id);
    },
    selectTask(selectedTask) {
      this.showSelectTaskDialog = false;
      const newTimer = { ...this.timer };
      newTimer.task = selectedTask;
      this.$store.dispatch('timer/updateTimer', newTimer);
    }
  }
}
</script>

<style lang="sass">
  .active
    &::before
      opacity: 0.04 !important

    &:hover
      &::before
       opacity: 0.1 !important 

  .timer-item
    min-height: 32px

    &:before 
      background-color: currentColor
      bottom: 0
      content: ""
      left: 0
      opacity: 0
      pointer-events: none
      position: absolute
      right: 0
      top: 0
      transition: .3s cubic-bezier(.25,.8,.5,1)

    &::after
      position: absolute
      border-bottom: 1px solid #e8e8e8
      width: calc(100% + 32px)
      height: 1px
      min-height: 1px
      left: -16px 
      bottom: 0

    .v-list-item__content
      height: 24px

      *
        flex: initial !important
    
  .hide-divider::after
    display: none
</style>