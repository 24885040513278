<template>
  <v-card>
    <v-card-subtitle
      class="d-flex justify-space-between px-4">
      <v-menu
        :disabled="timerIsStart"
        offset-y
        v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on">
            {{ ['番茄钟', '正向计时'][mode] }}
            <v-icon
              size="16"
              :style="menu && `transform: rotate(180deg)`">
              keyboard_arrow_down
            </v-icon>
          </span>
        </template>
        <v-list>
          <v-list-item
            @click="mode = [0 , 1][1 - mode]"
            dense
            link>
            <v-list-item-title>
              {{ ['番茄钟', '正向计时'][1 - mode] }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on">
            mdi-dots-horizontal
          </v-icon>
        </template>
      </v-menu>
    </v-card-subtitle>
    <div
      class="d-flex flex-column align-center pt-6 pb-12">
      <v-hover
        v-slot="{ hover }">
        <timer-status-progress
          :timerIsStart="timerIsStart"
          :timerMode="mode">
          <timer-tomato-time-picker
            :hover="hover">
          </timer-tomato-time-picker>
        </timer-status-progress>
      </v-hover>
      <timer-select-task 
       class="mt-6">
      </timer-select-task>
      <timer-button 
        class="mt-6"
        :type="timerIsStart ? 'stop' : 'start'"
        @clickOnStartTimer="startTimer"
        @clickOnStopTimer="stopTimer">
      </timer-button>
    </div>
  </v-card>
</template>

<script>
import TimerButton from './TimerButton.vue';
import TimerSelectTask from './TimerSelectTask.vue';
import TimerStatusProgress from './TimerStatusProgress.vue';
import { mapState, mapMutations } from 'vuex';
import TimerTomatoTimePicker from './TimerTomatoTimePicker.vue';

export default {
  components: { TimerSelectTask, TimerButton, TimerStatusProgress, TimerTomatoTimePicker },
  data() {
    return {
      menu: false,
      mode: 0 // 0: tomato, 1: count
    }
  },
  computed: {
    ...mapState('timer', ['ongoingTask']),
    timerIsStart() {
      return !this._.isNull(this.$store.state.timer.ongoingTimer);
    },
  },
  watch: {
    mode() {
      switch (this.mode) {
        case 0:
          
          break;
      
        case 1:
          this.changeMode({ mode: this.mode });
          this.changeTotal({ newValue: 0 });
          this.initTimer();
          break;

        default:
          break;
      }
    }
  },
  methods: {
    startTimer() {
      this.$store.dispatch('timer/startTimer');
    },
    stopTimer() {
      this.$store.dispatch('timer/stopTimer');
    },
    test() {
      // this.$store.commit('snackbar/SHOW', {
      //   type: 'success',
      //   content: '555'
      // });
      // this.$store.state.timer.timers
      //   .filter(timer => timer.duration === -1)
      //   .forEach(timer => this.$store.dispatch('timer/deleteTimer', timer.id));
      this.$store.dispatch('timer/deleteTimer', 216);
    },
    ...mapMutations('timer', {
      changeMode: 'CHANGE_MODE',
      changeTotal: 'CHANGE_TOTAL',
      initTimer: 'INIT_TIMER',
    })
  },
}
</script>