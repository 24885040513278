import _ from 'lodash';

const ADD_TASK = (state, task) => {
  // console.log(`mutation: ${task}`);
  console.log(task);
  state.tasks.push(task);
};

const GET_TASKS = (state, tasks) => {
  state.tasks = tasks;
};

const ADD_FILTER_TAG = (state, payload) => {
  state.filtedTags.push(payload.filtedTag);
};

const REMOVE_FILTER_TAG = (state, { tag }) => {
  state.filtedTags = _.without(state.filtedTags, tag);
};

export default {
  ADD_TASK,
  GET_TASKS,
  ADD_FILTER_TAG,
  REMOVE_FILTER_TAG
}