var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',{staticClass:"d-flex justify-space-between px-4"},[_c('v-menu',{attrs:{"disabled":_vm.timerIsStart,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(['番茄钟', '正向计时'][_vm.mode])+" "),_c('v-icon',{style:(_vm.menu && "transform: rotate(180deg)"),attrs:{"size":"16"}},[_vm._v(" keyboard_arrow_down ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){_vm.mode = [0 , 1][1 - _vm.mode]}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(['番茄钟', '正向计时'][1 - _vm.mode])+" ")])],1)],1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}])})],1),_c('div',{staticClass:"d-flex flex-column align-center pt-6 pb-12"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('timer-status-progress',{attrs:{"timerIsStart":_vm.timerIsStart,"timerMode":_vm.mode}},[_c('timer-tomato-time-picker',{attrs:{"hover":hover}})],1)]}}])}),_c('timer-select-task',{staticClass:"mt-6"}),_c('timer-button',{staticClass:"mt-6",attrs:{"type":_vm.timerIsStart ? 'stop' : 'start'},on:{"clickOnStartTimer":_vm.startTimer,"clickOnStopTimer":_vm.stopTimer}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }