import * as api from '@/api/api';
import dayjs from '@/plugins/dayjs';
import _ from 'lodash';

const parseTaskStr = (task) => {
  return _.mapValues(task, (v, k) => {
    return k === 'tag' ? JSON.parse(v) : k === 'setting' ? JSON.parse(v) : v
  });
};

const getTimers = async ({ commit, dispatch, rootState }) => {
  api.cli.token = rootState.account.token;
  return api.cli.getTimers().then(res => {
    if (res.type === 'data') {

      console.log(res.data);

      commit('GET_TIMERS', { timers: res.data.timers.sort((timerA, timerB) => {
        return timerA.start + timerA.duration * 1000 < timerB.start + timerB.duration * 1000 ? 1 : -1; 
      }) });

      const ongoingTimer = res.data.timers.filter(timer => timer.duration === -1);

      if (ongoingTimer.length) {
        dispatch('resumeTimer', ongoingTimer[0]);
      } else {
        commit('INIT_TIMER'); 
      }
    }

    return res;
  });
};
 
const resumeTimer = async ({ commit, dispatch, rootState }, timer) => {

  let resumedTimer = _.mapValues(timer, (v, k) => {
    return k === 'task' ? _.isNull(v) ? null : parseTaskStr(v) : v;
  });

  if (resumedTimer.stop) {
    // timer has set stop
    commit('CHANGE_TOTAL', { newValue: (resumedTimer.stop - resumedTimer.start) / 1000 });
  }

  if (!_.isNull(resumedTimer.task)) {
    // timer has task
    resumedTimer.task = rootState.tasks.tasks.find(task => task.id === resumedTimer.task.id);
    commit('SELECT_TASK', { task: resumedTimer.task });
  }

  commit('UPDATE_TIMER', { timer: resumedTimer });
  
  if (resumedTimer.stop <= Date.now()) {
    // timer over
    const duration = parseInt(dayjs.duration(resumedTimer.stop - resumedTimer.start, 'ms').as('s'), 10);
    commit('RESUME_TIMER', { resumedTimer, duration });
    dispatch('stopTimer');
  } else {
    // timer not over
    const duration = parseInt(dayjs.duration(Date.now() - resumedTimer.start, 'ms').as('s'), 10);
    commit('RESUME_TIMER', { resumedTimer, duration });
    dispatch('startTimer');
  }

};

const startTimer = async ({ state, commit, dispatch }) => {
  if (_.isNull(state.ongoingTimer)) {
    const newTimer = {
      start: Date.now(),
      stop: state.mode === 0 ? Date.now() + state.total * 1000 : 0,
      task: state.ongoingTask
    };
    dispatch('addTimer', newTimer);
    commit('CREATE_TIMER', { timer: newTimer });
  }

  commit('SET_INTERVAL', {
    interval: setInterval(() => {
      if (state.left.as('seconds') !== 0 || state.mode === 1)
        commit('INCREMENT_TIMER');
      else {
        dispatch('stopTimer');
      }
    }, 1000)
  });
};

const stopTimer = async ({ state, commit, dispatch, rootState }) => {
  api.cli.token = rootState.account.token;

  // play audio
  if (state.ongoingTimer.stop <= Date.now()) {
    const audio = new Audio('./audio/finish-1.mp3');
    audio.play();
  }

  if (state.total !== rootState.account.user.settings.lastUsedDefaultTomatoTime)
    dispatch('updateLastUsedDefaultTomatoTime', state.total);

  if (!_.isNull(state.ongoingTimer.task))
    dispatch('updateTask', state.ongoingTask, { root: true })

  dispatch('updateTimer', state.ongoingTimer)
    .then(() => {
      commit('STOP_TIMER');
      setTimeout(() => {
        commit('INIT_TIMER');
      }, 900);
    });
};

const addTimer = async ({ commit, rootState }, timer) => {

  console.log(timer);

  api.cli.token = rootState.account.token;
  return api.cli.addTimer(api.TimerItem.fromObj(timer)).then(res => {
    commit('UPDATE_TIMER', { timer: res.data });
  });
};

const updateTimer = ({ rootState, commit }, timer) => {
   api.cli.token = rootState.account.token;
   commit('UPDATE_TIMERS', { timer });
   return api.cli.updateTimer(api.TimerItem.fromObj(timer));
};

const deleteTimer = async ({ rootState, commit }, id) => {
   api.cli.token = rootState.account.token;
   commit('DELETE_TIMER', { id });
   return api.cli.deleteTimer(id);
};

const selectTask = ({ commit }, selectedTask) => {
  commit('SELECT_TASK', { task: selectedTask });
  commit('INIT_TIMER');
};

const updateLastUsedDefaultTomatoTime = ({ dispatch }, newValue) => {
  dispatch('updateSettings', {
    target: 'lastUsedDefaultTomatoTime',
    newValue
  }, {
    root: true
  });
};

export default {
  startTimer,
  stopTimer,
  resumeTimer,
  getTimers,
  addTimer,
  updateTimer,
  deleteTimer,
  selectTask,
  updateLastUsedDefaultTomatoTime
}