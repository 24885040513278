<template>
  <div class="task-add-dialog">
    <v-dialog
      v-model="showEditBox"
      attach=".task-add-dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          icon
          v-bind="attrs"
          v-on="on">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-row
        class="justify-center">
        <v-col
          cols="5">
          <v-card
            class="mx-auto rounded-lg"
            light>
            <v-card-subtitle>
              {{ isEditTask ? '编辑任务' : '添加任务' }}
            </v-card-subtitle>
            <v-card-text
              class="pb-0">
              <validation-provider
                rules="required"
                mode="aggressive"
                ref="inputText"
                name="Task text"
                v-slot="{ valid }"
                >
                <v-text-field
                  class="task-text-field pb-3"
                  placeholder="内容..."
                  v-model="task.text"
                  outlined
                  autocomplete="off"
                  hide-details
                  @keyup="textValid(valid)">
                  <template #append>
                    <task-time-picker
                      class="mr-2"
                      v-model="task.estSec">
                    </task-time-picker>
                    <task-date-picker
                      class="mr-2"
                      v-model="task.date">
                    </task-date-picker>
                    <task-tag-picker
                      v-model="inputTags">
                    </task-tag-picker>
                  </template>
                </v-text-field>
              </validation-provider>
              <v-radio-group
                v-model="task.setting.timerMode"
                class="mt-0 pb-3"
                v-if="task.estSec > 0"
                label="计时器模式"
                hide-details
                row>
                <v-radio
                  :value="0"
                  label="番茄钟">
                </v-radio>
                <v-radio
                  :value="1"
                  label="正向计时">
                </v-radio>
              </v-radio-group>
            </v-card-text>
            <div v-show="task.estSec > 0 && task.setting.timerMode === 0">
              <task-tomato-time-input v-model="task.setting.tomatoTime"></task-tomato-time-input>
              <task-break-time-input v-model="task.setting.breakingTime"></task-break-time-input>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="showEditBox = false;">
                关闭
              </v-btn>
              <v-btn
                text
                :disabled="isTextEmpty"
                @click="editDone">
                {{ isEditTask ? '完成' : '添加' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import Vue from 'vue';
import Bus from '@/utils/bus';
import { ValidationProvider } from 'vee-validate';
import TaskTimePicker from './TaskTimePicker.vue';
import TaskDatePicker from './TaskDatePicker.vue';
import TaskTomatoTimeInput from './TaskTomatoTimeInput.vue';
import TaskBreakTimeInput from './TaskBreakTimeInput.vue';
import TaskTagPicker from './TaskTagPicker.vue';
import dayjs from 'dayjs';
import { mapState, mapMutations } from 'vuex';

class TaskItem {
  constructor() {
    this.text = '';
    this.date = dayjs().unix();
    this.estSec = 0;
    this.tag = [];
    this.setting = {
      timerMode: -1,
      tomatoTime: 0,
      breakingTime: 0
    }
  }
}

export default Vue.extend({
  components: { ValidationProvider, TaskTimePicker, TaskDatePicker, TaskTomatoTimeInput, TaskBreakTimeInput, TaskTagPicker },
  data() {
    return {
      isTextEmpty: true,
      isEditTask: false,
      showEditBox: false,
      task: new TaskItem,
      oldTask: null,
      inputTags: []
    }
  },
  computed: {
    ...mapState('account', {
      lastUsedTomatoTime: state => state.user.settings.lastUsedTomatoTime,
      lastUsedBreakingTime: state => state.user.settings.lastUsedBreakingTime,
    })
  },
  methods: {
    ...mapMutations('account', {
      updateSetting: 'UPDATE_SETTING'
    }),
    async editDone() {
      this.$refs.inputText.validate().then(async (res) => {
        if (res.valid) {
          if (this.isEditTask) {
            // Edit task
            this._.assign(this.oldTask, this.task);
            await this.$store.dispatch('updateTask', this.task);
          } else {
            // Add task
            this.task.createdAt = this.dayjs().unix();
            await this.$store.dispatch('tasks/addTask', { ...this.task });
          }

          const isSameTomatoTime = this.lastUsedTomatoTime !== this.task.setting.tomatoTime,
                isSameBreakingTime = this.lastUsedBreakingTime !== this.task.setting.breakingTime;

          if (isSameTomatoTime || isSameBreakingTime) {
            if (isSameTomatoTime)  
              this.updateSetting({ target: 'lastUsedTomatoTime', newValue: this.task.setting.tomatoTime });
  
            if (isSameBreakingTime)  
              this.updateSetting({ target: 'lastUsedBreakingTime', newValue: this.task.setting.breakingTime });

            this.$store.dispatch('account/updateSettings');
          }

          this.$refs.inputText.reset();
          this.showEditBox = false;
        }
      })
    },
    textValid(valid) {
      this.isTextEmpty = !valid;
    }
  },
  watch: {
    showEditBox() {
      if (this.showEditBox) {
        if (this.task.tag) this.inputTags = this.task.tag;
        Bus.$emit('TaskEditBoxShow', this.task); 
      } else {
        setTimeout(() => {
          this.task = new TaskItem;
          this.isEditTask = false;
          this.isTextEmpty = true;
          this.inputTags = [];
          Bus.$emit('TaskEditBoxClose');
        }, 100);
      }
    },
  },
  mounted() {
    const vm = this;
    Bus.$on('editTask', (task) => {
      vm.showEditBox = true;
      vm.isTextEmpty = false;
      vm.isEditTask = true;
      this._.assign(vm.task, task);
      vm.oldTask = task;
    });
    Bus.$on('clickOnAddTask', () => {
      vm.task.date = vm.dayjs().format('YYYY-MM-DD');
      vm.task = new TaskItem;
      vm.isEditTask = false;
      vm.showEditBox = true;
    });
  }
})
</script>

<style lang="sass">
  .task-add-dialog
    .v-dialog
      overflow-y: initial !important

  .task-text-field
    .v-input__slot
      display: flex
      flex-direction: column

      .v-input__append-inner
        margin: 0
        padding: 0px 0 12px 0
        position: relative
        left: -8px

      input
        padding: 24px 0 24px 0

  
</style>