<template>
  <v-snackbar
    :value="snackbarVis"
    :timeout="timeout"
    top>
    <v-alert 
      :type="snackbarType">
      {{ snackbarCon }}
    </v-alert>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      timeout: 4000
    }
  },
  computed: {
    snackbarVis: {
      get() {
        return this.$store.state.snackbar.snackbarVis;
      },
    },
    snackbarType() {
      return this.$store.state.snackbar.snackbarType;
    },
    snackbarCon() {
      return this.$store.state.snackbar.snackbarCon;
    },
  },
  updated() {
    setTimeout(() => {
      this.$store.commit('snackbar/HIDE');
    }, 4000);
  }
}
</script>