<template>
  <v-chip 
    class="custom-chip"
    v-if="clickable"
    @click.stop.prevent="$emit('clickOnItemTag')"
    :style="{ backgroundColor: colorsPak[color].lighten4, color: colorsPak[color].base }"
    :ripple="false"
    small
    link>
    <slot></slot>
    <v-tooltip
      top>
      <template
        v-slot:activator="{ on }">
        <v-icon
          class="ml-1"
          small
          :style="{ color: colorsPak[color].base }"
          v-if="showClose"
          @click.stop.prevent="$emit('clickOnItemTagClose')"
          v-on="on">
          close
        </v-icon>
      </template>
      <span>移除</span>
    </v-tooltip>
  </v-chip>
</template>

<script>
import colorsPak from 'vuetify/lib/util/colors'

export default {
  props: ['color', 'showClose'],
  data() {
    return {
      colorsPak: colorsPak,
      clickable: true
    }
  }
}
</script>