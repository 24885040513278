<template>
  <v-card
    class="task-list-container">
    <v-card-subtitle class="pb-0">
      <div class="d-flex justify-space-between">
        <span>{{ new Date }}</span>
        <task-edit-box></task-edit-box>
      </div>
    </v-card-subtitle>
    <v-tabs-items
      :value="curTabIndex">
      <v-tab-item
        v-for="(tab, index) in tabs"
        :key="tabs[index].title + 'task'">
        <task-input :tab="curTabIndex"></task-input>    
        <task-list
          :range="tab.range"
          :showDate="tab.showDate"
          :showHint="tab.showHint"
          :tabLabel="tabs[index].title">
        </task-list>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Vue from 'vue'
import TaskEditBox from './TaskEditBox.vue';
// import TaskFilter from './TaskFilter.vue';
import TaskInput from './TaskInput.vue';
import TaskList from './TaskList.vue';

export default Vue.extend({
  components: { TaskInput, TaskList, TaskEditBox },
  data() {
    return {
      showAddBox: false,
      tab: 0,
      tabs: [{
        title: '今日',
        showDate: false,
        showHint: true,
        range: this.dayjs(),
      }, {
        title: '本周',
        showDate: false,
        showHint: true,
        range: this.dayjs().add(1, 'day')
      }, {
        title: '全部',
        showDate: true,
        showHint: true,
        range: null
      }],
    }
  },
  computed: {
    curTabIndex() {
      return this.$store.state.tabIndex;
    }
  }

})
</script>

<style lang="sass" scoped>

</style>