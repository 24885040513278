<template>
  <div>
    <v-progress-circular
      size="230"
      :width="20"
      :value="value"
      :rotate="270"
      color="primary">
      <span
        v-if="timerIsStart || timerMode === 1"
        style="font-size: 32px">
        {{ leftStr }}
      </span>
      <slot v-else-if="timerMode === 0"></slot>
    </v-progress-circular>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Bus from '@/utils/bus';

export default {
  components: {  },
  props: ['timerIsStart', 'timerMode'],
  data() {
    return {
      value: 100,
      subId: 0,
    }
  },
  computed: {
    ...mapGetters('timer', [
      'leftStr',
      'leftSec'
    ]),

    ...mapState('timer', {
      ongoingTimer: 'ongoingTimer',
      progressTotal: 'total',
      hasOngoingTimer() { return !this._.isNull(this.ongoingTimer) }
    })
  },
  methods: {
    count() {
      const sub = () => {
        this.value = this.getValue();
        if (this.value > 0) this.subId = requestAnimationFrame(sub);
        else {
          this.reset();
        }
      }

      this.subId = requestAnimationFrame(sub);
    },
    getValue() {
      if (!this.ongoingTimer) return 100;
      return (this.progressTotal * 1000 - (Date.now() - this.ongoingTimer.start)) / (this.progressTotal * 1000) * 100
    },

    reset() {
      cancelAnimationFrame(this.subId);
      const clear = () => { 
        this.value -= 4;
        if (this.value > 0) requestAnimationFrame(clear);
        else {
          setTimeout(() => {
            requestAnimationFrame(add);
          }, 800);
        }
      };

      const add = () => {
        this.value += 4;
        if (this.value < 100) requestAnimationFrame(add);
      };

      if (this.value > 0) requestAnimationFrame(clear);
      else {
        setTimeout(() => {
          requestAnimationFrame(add);
        }, 800);
      }
    }
  },
  beforeMount() {
    if (this.hasOngoingTimer) this.count();
    Bus.$on('clickOnStartTimer', () => {
      if (this.timerMode === 0)
        this.count();
    });
    Bus.$on('clickOnStopTimer', () => {
      this.reset();
    });
  }
}
</script>