<template>
  <div class="pa-6">
    <task-container></task-container>
  </div>
</template>

<script>
import TaskContainer from '@/components/Task/TaskContainer.vue';
// import MainProgress from '@/components/MainProgress.vue';

export default {
    components: {  TaskContainer },
}
</script>

<style lang="sass">
</style>